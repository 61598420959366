import React from "react";
import Doctors from "../../../assets/hra/landingPics1.png";
import LandingBGNew1 from "../../../assets/PG.jpeg";

import LargeServiceButton1 from "../../Atoms/Buttons/ServiceCard/LargeServiceButton1";
import "./healthCheckup.css";

function HealthCheckup() {
  return (
    <div className="large-card1"  style={{
      backgroundImage: `url(${LandingBGNew1})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
    }} >
      <div className="large-card1-text">
        <div className="large-card1-title">Bike &  SUV Tour Packages 2024</div>
        <div className="large-card1-para">
          {/* <span >

          With all-encompassing and affordable health<br /> checkups, taking charge of your health has<br /> never been easier
          </span> */}
          
        </div>
        <a href="/packages">
          <LargeServiceButton1 text={"View Now"} />
        </a>
      </div>
      {/* <img   className="large-card1-imgs" src={Doctors}>
      </img> */}

      {/* <div
        className="large-card1-img"
        style={{ backgroundImage: `url(${Doctors})` }}
      ></div> */}
    </div>
  );
}

export default HealthCheckup;



