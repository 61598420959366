import React from "react";
import SmallServiceButton from "../../Atoms/Buttons/ServiceCard/SmallServiceButtonCashless";
import "./Cashless.css";
// import OTP from "../../Organisms/OTP/OTP.js"
import LandingBGNew2 from "../../../assets/BIKE22.jpeg";

function Cashless() {
  return (
    <div
      className="small-card"
      style={{
        backgroundImage: `url(${LandingBGNew2})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="small-card-body">
        <div className="large-card1-title">
          RENT <br />
          BIKE{" "}
        </div>
        {/* <div className="small-card-para">
          Our consultation services are
          <br />
          designed to work in collaboration
          <br /> with your overall healthcare plan
        </div> */}
        <a href="/coming-soon">
          <SmallServiceButton text={"Visit Now"} />
        </a>
      </div>
    </div>
  );
}

export default Cashless;
