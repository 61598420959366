import React, { useEffect, useState } from "react";

import Footer from "../../Organisms/Footer/Footer";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";

import { DataGrid } from "@mui/x-data-grid";
import "./Tariff.css";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";

function Tariff() {
  const [isMobile, setIsMobile] = useState(false);

  const data = [
    {
      id: 1,
      roomCategory: "Superiour Room",
      cp: "Rs. 4000/-",
      map: "Rs. 4500/-",
      ap: "Rs. 5000/-",
    },
    {
      id: 2,
      roomCategory: "Delux Room",
      cp: "Rs. 5000/-",
      map: "Rs. 5500/-",
      ap: "Rs. 6000/-",
    },
    {
      id: 3,
      roomCategory: "Suit Room",
      cp: "Rs. 9000/-",
      map: "Rs. 9500/-",
      ap: "Rs. 10000/-",
    },
    {
      id: 4,
      roomCategory: "Family Suit Room",
      cp: "Rs. 12000/-",
      map: "Rs. 12500/-",
      ap: "Rs. 13000/-",
    },
    { id: 5, roomCategory: "Extra Adult Rates", cp: "40% Extra" },

    { id: 6, roomCategory: "Extra Child Rates", cp: "20% Extra" },
  ];

  const columns = [
    {
      field: "roomCategory",
      headerClassName: "super-app-theme--header",
      headerName: " Room Category",
      flex: 3,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },

    {
      field: "cp",
      headerClassName: "super-app-theme--header",
      headerName: "CP",
      flex: 3,
      alignItems: "Right",
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },

    {
      field: "map",
      headerClassName: "super-app-theme--header",
      headerName: "MAP",
      flex: 3,
      alignItems: "Right",
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "ap",
      headerClassName: "super-app-theme--header",
      headerName: "AP",
      flex: 3,
      alignItems: "Right",
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
  ];
  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.outerWidth]);
  return (
    <>
    <ScrollToTop/>
    <Helmet>
        <title>Tariff - Best Hotel in Ladakh | Ladakh Heights</title>
        <meta
          name="description"
          content="Explore the tariff options for accommodations at Ladakh Heights, the best hotel in Ladakh. Choose from a range of room categories including Superior, Deluxe, Suite, and Family Suite, with various meal plans."
        />
        <meta
          name="keywords"
          content="tariff, room rates, hotel rates, Ladakh hotel tariff, Superior Room, Deluxe Room, Suite Room, Family Suite Room, Extra Adult Rates, Extra Child Rates"
        />
        <meta name="robots" content="index, follow" />
        </Helmet>

      {isMobile ? (
        <>
         <div className="AboutUsBody">
         <div
           style={{
             backgroundImage: `url(${"https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-13.webp"})`,
             backgroundRepeat: "no-repeat",
             // backgroundSize: "100%",
             backgroundSize: "cover", // Ensures the image covers the entire container
             backgroundPosition: "center", // Centers the image within the container
             width: "100%", // Set the desired width for your container
             height: "30vh", // Set the desired height for your container
             display: "flex",
             alignItems: "center",
             justifyContent: "center",
           }}
           className="AboutUsHeadingImage"
         >
           <div className="AboutUsHeading">
             {/* <p>LUXURY HOTEL  EXPERIENCE</p> */}
             <h2 className="mobileHeadings"  >TARIFF</h2>
           </div>
         </div>

         <div
           style={{
            backgroundColor:"#faf8f5",
             paddingLeft: "4vw",
             paddingRight: "4vw",
             paddingBottom: "4vw",
           }}
         >
           <Box
             sx={{
               height: 600,
               display: "inline-block",
               width: "100%",
               marginTop: "2%",
             }}
           >
             {data && (
               <DataGrid
                 sx={{
                   color: "#1D2334",
                   "& .super-app-theme--header": {
                     backgroundColor: "#52608E",
                     color: "#F2EFEA",
                     fontFamily: "Nunito",
                     fontWeight: 600,
                     "& > .MuiDataGrid-columnSeparator": {
                       visibility: "hidden",
                     },
                   },
                   fontFamily: "Nunito",
                   "&:hover:not(.Mui-disabled)": {
                     cursor: "pointer",
                   },
                   ".MuiTablePagination-toolbar": {
                     alignItems: "baseline",
                     marginTop: "5%",
                   },
                 }}
                 rows={data}
                 columns={columns}
                 components={{
                   Footer: "none",
                 }}
               />
             )}
           </Box>
         </div>
       </div>
       <Footer />
       </>
      ) : (
        <>
          <div className="AboutUsBody">
            <div
              style={{
                backgroundImage: `url(${"https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-13.webp"})`,
                backgroundRepeat: "no-repeat",
                // backgroundSize: "100%",
                backgroundSize: "cover", // Ensures the image covers the entire container
                backgroundPosition: "center", // Centers the image within the container
                width: "100%", // Set the desired width for your container
                height: "60vh", // Set the desired height for your container
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="AboutUsHeadingImage"
            >
              <div className="AboutUsHeading">
                {/* <p>LUXURY HOTEL  EXPERIENCE</p> */}
                <h2 style={{ fontSize: "3vw" }}>TARIFF</h2>
              </div>
            </div>

            <div
              style={{
                paddingLeft: "14vw",
                paddingRight: "14vw",
                paddingBottom: "4vw",
              }}
            >
              <Box
                sx={{
                  height: 600,
                  display: "inline-block",
                  width: "100%",
                  marginTop: "2%",
                }}
              >
                {data && (
                  <DataGrid
                    sx={{
                      color: "#1D2334",
                      "& .super-app-theme--header": {
                        backgroundColor: "#52608E",
                        color: "#F2EFEA",
                        fontFamily: "Nunito",
                        fontWeight: 600,
                        "& > .MuiDataGrid-columnSeparator": {
                          visibility: "hidden",
                        },
                      },
                      fontFamily: "Nunito",
                      "&:hover:not(.Mui-disabled)": {
                        cursor: "pointer",
                      },
                      ".MuiTablePagination-toolbar": {
                        alignItems: "baseline",
                        marginTop: "5%",
                      },
                    }}
                    rows={data}
                    columns={columns}
                    components={{
                      Footer: "none",
                    }}
                  />
                )}
              </Box>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default Tariff;
