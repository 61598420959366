import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import rec1 from "../../../assets/LandingpageCarousel/rec1.png";
import Location from "../../../assets/LandingPage/Location on.png";
import rec2 from "../../../assets/LandingpageCarousel/rec2.png";
import rec3 from "../../../assets/LandingpageCarousel/rec3.png";
import rec4 from "../../../assets/LandingpageCarousel/rec4.png";
import rec5 from "../../../assets/LandingpageCarousel/rec5.png";
import rec6 from "../../../assets/LandingpageCarousel/rec6.png";
import tab from "../../../assets/Navigation/tab.png";
import RightArrow from "../../../assets/common/RightArrow.png";
import "./NavigtaionTabs.css";

export default function NavigationTabs() {

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popper" : undefined;

  const [tabs, setTabs] = React.useState({
    home: false,
    service: false,
    network: false,
    subscribe: false,
    company: false,
    package:false,
  });

  const handleClick1 = (event) => {
    console.log("clicked");
    setAnchorEl1(anchorEl1 ? null : event.currentTarget);
  };

  const cityNames = [];


  // viewCityNames?.data?.data?.data.data.map((item) => {
  //   cityNames.push(item.name);
  // });
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popper" : undefined;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  // useEffect(() => {
  //   dispatch(viewAllCityRequest());
  // }, []);

  const clickaway = () => {
    setAnchorEl1(null);
  };
  const clickAway2 = () => {
    setAnchorEl2(null);
  };
  function handle1() {
    navigate("/consultation/cashless-opd");
    setAnchorEl(null);
    setValue("Services");
  }
  function handle2() {
    navigate("/talk-to-doctor");
    setAnchorEl(null);
    setValue("Services");
  }
  function handle3() {
    navigate("/view-every");
    setAnchorEl(null);
    setValue("Services");
  }
  function handle4() {
    if (localStorage.getItem("token") === null) {
      navigate("/health-risk-assessment");
      setAnchorEl(null);
      setValue("Services");
    } else {
      navigate("/health-risk-assessment-locked");
    }
    setAnchorEl(null);
    setValue("Services");
  }
  function handle5() {
    if (localStorage.getItem("token") === null) {
      navigate("/rental-equipment");
      setAnchorEl(null);
      setValue("Services");
    } else if (localStorage.getItem("token") !== null) {
      navigate("/rental-equipment");
    }
  }
  function handle6() {
    navigate("/about-us");
    setAnchorEl3(null);
    setValue("Company");
  }
  function handle7() {
    navigate("/career");
    setAnchorEl3(null);
    setValue("Company");
  }
  function handle8() {
    navigate("/life-at-yuva");
    setAnchorEl3(null);
    setValue("Company");
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBookNow = () => {
    setTabs({
      home: false,
      service: false,
      network: false,
      subscribe: false,
      company: false,
      package:false,
    })
    if (window.location.pathname !== "/view-test") {
      localStorage.setItem(
        "viewEvery",
        JSON.stringify({
          radioItem: "NO_RANGE",
          minPrice: 0,
          maxPrice: 10000,
          allChecked: ["Test"],
        })
      );
      navigate("/view-test");
    }
  };
  const handleHomeClick = () => {
    navigate("/");
    setTabs({
      home: true,
      service: false,
      network: false,
      subscribe: false,
      company: false,
      package:false,

      mentalWelness: false,
    });
  };
  const handleNetworkClick = () => {
    navigate("/coming-soon");
    setTabs({
      home: false,
      service: false,
      network: true,
      subscribe: false,
      company: false,
      package:false,

      mentalWelness: false,
    });
  };
  const handleMentalWelnessClick = () => {
    navigate("/coming-soon");
    setTabs({
      home: false,
      service: false,
      network: false,
      subscribe: false,
      company: false,
      mentalWelness: true,
      package:false,

    });
  };
  const handleSubscribeClick = () => {
    navigate("/coming-soon");
    setTabs({
      home: false,
      service: false,
      network: false,
      subscribe: true,
      company: false,
      mentalWelness: false,
      package:false,

    });
  };
  const handlePackageClick = () => {
    navigate("/packages");
    setTabs({
      home: false,
      service: false,
      network: false,
      subscribe: false,
      company: false,
      mentalWelness: false,
      package:true,
    });
  };

  return (
    <div>
      <div className="LocationMainBox">
        <img className="LocationNavigationDropDownImage" src={Location}></img>
        <div className="LocationMainBoxTitle">
          <select>
            {cityNames.map((name, index) => {
              return (
                <option className="locationOptions" value={index}>
                  {name}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <Box
        sx={{
          alignItems: "baseline",
          paddingBottom: "1vw",
          display: "flex",
          justifyContent: "flexStart",
          paddingLeft: "9vw",
          justifyContent: "space-evenly",
        }}
      >
        <Tabs value={value} onChange={handleChange} centered>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#1D2334",
            }}
          >
            <Button
              className={tabs.home ? "tabNameTrue" : "tabName"}
              label="Home"
              onClick={() => {
                handleHomeClick();
              }}
              disableElevation
            >
              Home
            </Button>
            {tabs.home && (
              <img
                src={tab}
                alt=""
                style={{
                  height: "6.62px",
                  width: "8.13px",

                  marginLeft: "1vw",
                }}
              />
            )}
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ClickAwayListener onClickAway={clickaway}>
              <Button
                sx={
                  tabs.service
                    ? {
                        color: "#e68d36",
                        fontSize: "1.1vw",
                        fontWeight: "600",
                        paddingLeft: "2vw",
                      }
                    : {
                        color: "#1D2334",
                        fontSize: "1.1vw",
                        fontWeight: "600",
                        paddingLeft: "2vw",
                      }
                }
                aria-controls={open1 ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
              >
                PACKAGES
              </Button>
            </ClickAwayListener>
            {tabs.service && (
              <img
                src={tab}
                alt=""
                style={{
                  height: "6.62px",
                  width: "8.13px",

                  marginLeft: "1vw",
                }}
              />
            )}
          </div> */}
             <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              className={tabs.package ? "tabNameTrue" : "tabName"}
              label="Network"
        
              onClick={() => handlePackageClick()}

            >
           PACKAGES
            </Button>
            {tabs.package && (
              <img
                src={tab}
                alt=""
                style={{
                  height: "6.62px",
                  width: "8.13px",
                  marginLeft: "1vw",
                }}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              className={tabs.mentalWelness ? "tabNameTrue" : "tabName"}
              label="Network"
              onClick={() => handleMentalWelnessClick()}
            >
            RENT CAR
            </Button>
            {tabs.mentalWelness && (
              <img
                src={tab}
                alt=""
                style={{
                  height: "6.62px",
                  width: "8.13px",
                  marginLeft: "1vw",
                }}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              className={tabs.network ? "tabNameTrue" : "tabName"}
              label="Network"
              onClick={() => handleNetworkClick()}
            >
              RENT BIKE
            </Button>
            {tabs.network && (
              <img
                src={tab}
                alt=""
                style={{
                  height: "6.62px",
                  width: "8.13px",

                  marginLeft: "1vw",
                }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              className={tabs.subscribe ? "tabNameTrue" : "tabName"}
              label="Subscribe"
              onClick={() => {
                handleSubscribeClick();
              }}
            >
              ATTRACTIONS
            </Button>
            {tabs.subscribe && (
              <img
                src={tab}
                alt=""
                style={{ height: "6.62px", width: "8.13px", marginLeft: "1vw" }}
              />
            )}
          </div>

          <Popper id={id1} open={open1} anchorEl={anchorEl1}>
            <div className="NavbarGridContainer">
              <div>
                <Box
                  className="BoxForPopper"
                  sx={{
                    border: 1,
                    p: 1,
                    bgcolor: "background.paper",
                    border: "hidden",
                    width: "49vw",
                  }}
                >
                  <MenuItem
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    onClick={() => {
                      navigate("/consultation/cashless-opd");
                      setAnchorEl(null);
                      setValue("Services");
                      setTabs({
                        home: false,
                        service: true,
                        network: false,
                        subscribe: false,
                        company: false,
                        mentalWelness: false,
                      });
                    }}
                    onTouchStart={handle1}
                  >
                    <img src={RightArrow} className="arrowImage" />{" "}
                    <span className="NavbarNames">
                      {" "}
                      OPD Consultation Program
                    </span>
                  </MenuItem>
                  <MenuItem
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    onClick={() => {
                      navigate("/talk-to-doctor");
                      setAnchorEl(null);
                      setValue("Services");
                      setTabs({
                        home: false,
                        service: true,
                        network: false,
                        subscribe: false,
                        company: false,
                        mentalWelness: false,
                      });
                    }}
                    onTouchStart={handle2}
                  >
                    <img src={RightArrow} className="arrowImage" />
                    <span className="NavbarNames"> Talk to Doctor</span>
                  </MenuItem>
                  <MenuItem
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    onClick={() => {
                      navigate("/view-test");
                      setAnchorEl(null);
                      setValue("Services");
                      setTabs({
                        home: false,
                        service: true,
                        network: false,
                        subscribe: false,
                        company: false,
                        mentalWelness: false,
                      });
                    }}
                    onTouchStart={handle3}
                  >
                    <img src={RightArrow} className="arrowImage" />{" "}
                    <span className="NavbarNames">
                      Health Checkup & Diagnostics
                    </span>
                  </MenuItem>
                  <MenuItem
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    onClick={() => {
                      if (localStorage.getItem("token") === null) {
                        navigate("/health-risk-assessment");
                        setAnchorEl(null);
                        setValue("Services");
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                          mentalWelness: false,
                        });
                      } else {
                        navigate("/health-risk-assessment-locked");
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                          mentalWelness: false,
                        });
                      }
                    }}
                    onTouchStart={handle4}
                  >
                    <img src={RightArrow} className="arrowImage" />{" "}
                    <span className="NavbarNames">Health Risk Assessment</span>
                  </MenuItem>
                  <MenuItem
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    onClick={() => {
                      if (localStorage.getItem("token") === null) {
                        navigate("/rental-equipment");
                        setAnchorEl(null);
                        setValue("Services");
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                          mentalWelness: false,
                        });
                      } else {
                        navigate("/rental-equipment");
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                          mentalWelness: false,
                        });
                      }
                    }}
                    onTouchStart={handle5}
                  >
                    <img src={RightArrow} className="arrowImage" />{" "}
                    <span className="NavbarNames">Rental Equipment</span>
                  </MenuItem>
                  <MenuItem
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    onClick={() => {
                      if (localStorage.getItem("token") === null) {
                        navigate("/EMRM");
                        setAnchorEl(null);
                        setValue("Services");
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                        });
                      } else {
                        navigate("/EMRM");
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                        });
                      }
                    }}
                    onTouchStart={handle5}
                  >
                    <img src={RightArrow} className="arrowImage" />{" "}
                    <span className="NavbarNames">EMRM</span>
                  </MenuItem>
                </Box>
              </div>
              <div className="NavbarGridContaineropt">
                <>
                  <div>
                    <img className="NavbarImages" src={rec1}></img>
                  </div>
                  <div>
                    <img className="NavbarImages" src={rec2}></img>
                  </div>
                  <div>
                    <img className="NavbarImages" src={rec3}></img>
                  </div>

                  <div>
                    <img className="NavbarImages" src={rec4}></img>
                  </div>
                </>
              </div>
            </div>
          </Popper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ClickAwayListener onClickAway={clickAway2}>
              <Button
                sx={
                  tabs.company
                    ? {
                        color: "#e68d36",
                        fontSize: "1.1vw",
                        fontWeight: "600",
                        paddingLeft: "2vw",
                      }
                    : {
                        color: "#1D2334",
                        fontSize: "1.1vw",
                        fontWeight: "600",
                        paddingLeft: "2vw",
                      }
                }
                aria-controls={open2 ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={handleClick2}
              >
                Company
              </Button>
            </ClickAwayListener>
            {tabs.company && (
              <img
                src={tab}
                alt=""
                style={{ height: "6.62px", width: "8.13px" ,
              
              
                marginLeft: "1vw",
              
              }}
              />
            )}
          </div>

          <Popper id={id2} open={open2} anchorEl={anchorEl2}>
            <div className="NavbarGridContainer">
              <div>
                <Box
                  className="BoxForPopper2"
                  sx={{
                    border: 1,
                    p: 1,
                    bgcolor: "background.paper",
                    border: "hidden",
                  }}
                >
                  <MenuItem
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    onClick={() => {
                      navigate("/AboutUs");
                      setAnchorEl3(null);
                      setValue("Company");
                      setTabs({
                        home: false,
                        service: false,
                        network: false,
                        subscribe: false,
                        company: true,
                        mentalWelness: false,
                      });
                    }}
                    onTouchStart={handle6}
                  >
                    <img src={RightArrow} className="arrowImage" />{" "}
                    <span className="NavbarNames">About Us</span>
                  </MenuItem>
                  <MenuItem
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    onClick={() => {
                      navigate("/ContactUs");
                      setAnchorEl3(null);
                      setValue("Company");
                      setTabs({
                        home: false,
                        service: false,
                        network: false,
                        subscribe: false,
                        company: true,
                        mentalWelness: false,
                      });
                    }}
                    onTouchStart={handle7}
                  >
                    <img src={RightArrow} className="arrowImage" />{" "}
                    <span className="NavbarNames">Contact Us</span>
                  </MenuItem>
                </Box>
              </div>
              <div className="NavbarGridContaineropt2">
                <>
                  <div>
                    <img className="NavbarImages" src={rec5}></img>
                  </div>
                  <div>
                    <img className="NavbarImages" src={rec6}></img>
                  </div>
                </>
              </div>
            </div>
          </Popper>
        </Tabs>

        <div className="NavigationDiscountSection">
          <div>  10 % DISCOUNT ON EARLYBIRD </div>
          <button
            className="NavigationDiscountSectionButton"
            onClick={handleBookNow}
          >
            BOOK NOW
          </button>
        </div>
      </Box>
    </div>
  );
}
