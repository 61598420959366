import React from 'react'
import './Testimonal.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import persona1 from '../../../assets/ContactUs/persona/persona33.jpeg'
import persona2 from '../../../assets/ContactUs/persona/persona222.jpeg'
import persona3 from '../../../assets/ContactUs/persona/persona11.jpeg'
import persona4 from '../../../assets/ContactUs/persona/persona4.jpeg'
import { Helmet } from 'react-helmet'

import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow
} from 'mdb-react-ui-kit'

export default function App () {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          prevArrow: null, // Remove the previous arrow button
          nextArrow: null,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  }
  return (
    <>
      {/* <Helmet>
        <title>Testimonials - Best Hotel in Ladakh | Ladakh Heights</title>
        <meta
          name="description"
          content="Read testimonials from guests about Ladakh Heights, the best hotel in Ladakh. Discover the luxurious accommodations, breathtaking mountain views, and tranquil surroundings."
        />
        <meta
          name="keywords"
          content="best hotel in Ladakh, Ladakh Heights, Ladakh hotel testimonials, luxurious accommodations, mountain views, tranquil surroundings"
        />
        <meta name="robots" content="index, follow" />
      </Helmet> */}

      <MDBContainer className='py-5'>
        <MDBRow
          style={{ justifyContent: 'left' }}
          className='d-flex justify-content-center'
        >
          <div
            style={{ paddingLeft: '4vw', paddingBottom: '6vw' }}
            className='text-center1'
          >
            <h2>Testimonials</h2>
            <i className='textHeadingTestimonal'>
              An Unforgettable Escape:
              <span className='ladakhColor'> Journey Through Ladakh  </span>
              Exceeds Expectations!
            </i>
          </div>
        </MDBRow>
        <MDBRow className='text-center d-flex align-items-stretch'>
          <Slider {...settings}>
            <MDBCol md='4' className='mb-5 mb-md-0 d-flex align-items-stretch'>
              <MDBCard className='testimonial-card'>
                <div
                  className='card-up'
                  style={{ backgroundColor: '#6d5b98' }}
                ></div>
                <div className='avatar mx-auto bg-white'>
                  <img src={persona2} className='rounded-circle img-fluid' />
                </div>
                <MDBCardBody>
                  <h4 className='mb-4'>Rakesh Kumar</h4>
                  <hr />
                  <p className='dark-grey-text mt-4'>
                    <MDBIcon fas icon='quote-left' className='pe-2' />
                    Journey through Ladakh is the best and oldest travel agency,
                    run by women since 1994. They offer great packages that are
                    affordable and safe for women. If you're planning a trip to
                    Ladakh, definitely go with them!
                  </p>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md='4' className='mb-5 mb-md-0 d-flex align-items-stretch'>
              <MDBCard className='testimonial-card'>
                <div
                  className='card-up'
                  style={{ backgroundColor: '#7a81a8' }}
                ></div>
                <div className='avatar mx-auto bg-white'>
                  <img src={persona3} className='rounded-circle img-fluid' />
                </div>
                <MDBCardBody>
                  <h4 className='mb-4'>Gaurav </h4>
                  <hr />
                  <p className='dark-grey-text mt-4'>
                    <MDBIcon fas icon='quote-left' className='pe-2' />
                    Had an incredible adventure in Ladakh with Journey through
                    Ladakh! Their team is so welcoming and made sure everything
                    was perfect. Plus, their prices are unbeatable. Highly
                    recommend!
                  </p>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol md='4' className='mb-5 mb-md-0 d-flex align-items-stretch'>
              <MDBCard className='testimonial-card'>
                <div
                  className='card-up'
                  style={{ backgroundColor: '#6d5b98' }}
                ></div>
                <div className='avatar mx-auto bg-white'>
                  <img src={persona1} className='rounded-circle img-fluid' />
                </div>
                <MDBCardBody>
                  <h4 className='mb-4'>Priya </h4>
                  <hr />
                  <p className='dark-grey-text mt-4'>
                    <MDBIcon fas icon='quote-left' className='pe-2' />
                    Such a fantastic trip with Journey through Ladakh! From
                    start to finish, they took care of everything and made me
                    feel so safe as a solo female traveler. Can't wait to book
                    with them again!
                  </p>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </Slider>
        </MDBRow>
      </MDBContainer>
    </>
  )
}
