import React from 'react'
import "./index.css"

function LargeServiceButton1(props) {
  return (
    <button className="largeViewButton1">{props.text}</button>
        
  )
}

export default LargeServiceButton1