import React, { useEffect, useState } from "react";
import FooterBackgroundImgArc from "../../../assets/common/FooterBackgroundImage.png";
import FooterBackgroundImg from "../../../assets/common/FooterBackgroundImg.png";
import FooterImg from "../../../assets/common/FooterImg.png";
import Footer from "../../Organisms/Footer/Footer";
import "./TermsAndConditions.css";
import { Helmet } from "react-helmet";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";

function TermsAndConditions() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    }
  }, [window.outerWidth]);
  return (
    <div className="mainTermsAndConditions">
      <ScrollToTop/>
     <Helmet>
        <title>About Ladakh Heights Hotel | Luxury Boutique Retreat in Ladakh</title>
        <meta
          name="description"
          content="Discover the allure of Ladakh Heights, a luxury boutique retreat located just 4kms from the bustling market. Enjoy captivating panoramas, spacious rooms, and traditional aesthetics amidst breathtaking vistas. Book your stay now!"
        />
        <meta
          name="keywords"
          content="ladakh heights, ladakh hotel, ladakh boutique hotel, ladakh luxury hotel, ladakh retreat, ladakh accommodations"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* {isMobile?<div style={{marginLeft:"-4em",marginBottom:".5em"}}> <Breadcrumb /></div>:<div><Breadcrumb /></div>} */}
      <div className="termsAndConditionsTop">
        <img
          src={FooterBackgroundImg}
          alt="FooterBackgroundImg"
          className="FooterBackgroundImage"
        />
        <img
          src={FooterBackgroundImgArc}
          alt="FooterBackgroundImgArc"
          className="FooterBackgroundImgArc"
        />
        <img src={FooterImg} alt="FooterImg" className="FooterImage" />
      </div>
      <div className="termsAndConditionsBottom">
        <div className="contentTermsAndConditions">
          <span className="contentTermsAndConditionsHeading">
            TERMS AND CONDITIONS
          </span>
          <br />
          <span className="contentTermsAndConditionsBottomHeading">
            Acknowledgement and acceptance of these terms of services
          </span>

          <br />
        
          <br />
          <span className="contentTermsAndConditionsBottomHeading">
          Key terms
          </span>
          <p className="includedRedirect">
          These Terms are an agreement between Journey Through Ladakh  ('we', 'us', 'our') and our clients ('you') who want to use our motorcycle tours, treks, expeditions, personalized itineraries, and mountain fitness guidance ('Services').
          </p>

          <span className="contentTermsAndConditionsBottomHeading">
          Our contract
          </span>

          <p className="includedRedirect">
          When you book with JOURNEY THROUGH LADAKH, you're booking with JOURNEY THROUGH LADAKH, a registered company in India. By booking with us, you agree to our TERMS OF SERVICE. We'll provide the services outlined in your booking confirmation and personalized itinerary.
          </p>

          <span className="contentTermsAndConditionsBottomHeading">
          Services
          </span>
          <p className="includedRedirect">
          We offer a range of outdoor activities like motorcycle touring, trekking, and peak climbing. We also provide motorcycle rentals, honeymoon trips, car rentals, hotels, package tours, and guidebooks.
          </p>

          <span className="contentTermsAndConditionsBottomHeading">
          Inclusions
          </span>
          <p className="includedRedirect">
          The land price of your trip includes:
          <br/>
          all accommodation as listed in the Trip Notes
          <br/>

all transport listed in the Trip Notes
<br/>

sightseeing and meals as listed in the Trip Notes
<br/>

the services of a group leader as described in the Trip Notes
         
<br/>
          </p>

          <span className="contentTermsAndConditionsBottomHeading">
          Exclusions
          </span>
          <p className="includedRedirect">
          The land price of your trip does not include:
          <br/>
          <br/>

International or internal flights unless specified
<br/>

Kitty as specified in the Trip Notes
<br/>

Airport transfers, taxes and excess baggage charges unless specified
<br/>

Meals other than those specified in the Trip Notes
<br/>

Visa and passport fees
<br/>

Travel insurance for Adventure activities
<br/>

Optional activities and all personal expenses
<br/>

Prices & surcharges
<br/>

          </p>

          <span className="contentTermsAndConditionsBottomHeading">
          Age & Health requirements
          </span>
          <p className="includedRedirect">
          Minimum Age Policy:
<br/>
<br/>



Most of our trips require travelers to be at least 15 years old.
<br/>

Travelers under 18 must be accompanied by a legal guardian or an adult appointed by the guardian.
<br/>

The guardian or appointee is responsible for the under-18 traveler's well-being.
<br/>

Triple or joining rooms for families aren't guaranteed, and accompanying adults might need to share a twin room with others.
<br/>
<br/>


Maximum Age Policy:
<br/>

<br/>


There's usually no upper age limit for our trips, but they can be physically demanding.
<br/>

Travelers should ensure they're fit enough to participate fully.
<br/>

We can provide information on health requirements, but it's up to you to get proper medical advice at least two months before travel.
<br/>






          </p>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
