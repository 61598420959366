import React, { useState, useEffect } from "react";
import "./LandingSecondPage.css"; // Import your CSS file for styles

const LandingSecondPage = () => {


  const amenitiesData = [
    { name: 'Free Wi-Fi', icon: 'wifi' },
    { name: 'Swimming Pool', icon: 'pool' },
    { name: 'Gym', icon: 'gym' },
    // Add more amenities as needed
  ];
  
  const Amenity = ({ name, icon }) => (
    <div className="amenity">
      <i className={`fas fa-${icon}`}></i>
      <span>{name}</span>
    </div>
  );
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.outerWidth <= 600);
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Initial check for mobile/desktop view
    handleResize();

    // Cleanup listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={isMobile ? "landingSecondPageMobile" : "landingSecondPageDesktop"}>
      <div className="landingPageContentBody">
        <div className="landingSecondPageHeading">Journey Through Ladakh</div>
        <div className="landingSecondPageSubHeading1">Where Experience Meets Adventure: Explore Ladakh with Confidence!</div>
        <div className="landingSecondPageContent">
        Embark on an Unforgettable Journey with Ladakh's Premier Women-Led Travel Agency! Pioneering Adventure Since 1994, we Bring Decades of Experience, Tailored Tour Packages, and Unbeatable Prices. With In-Depth Knowledge of Every Route and a Passion for Excellence, Trust Us to Make Your Ladakh Adventure Truly Exceptional
        </div>
        <div className="landingSecondPageSubHeading3">
        Book with Confidence: Discover Ladakh's Hidden Gems with Our 24 Years of Expertise, Tailored Tours, and Unbeatable Prices. Your Adventure Awaits
        </div>
      </div>
      
    </div>
  );
};

export default LandingSecondPage;
