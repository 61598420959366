import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./ViewGallery.css";
import Footer from "../../Organisms/Footer/Footer";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";

const ViewGallery = () => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.outerWidth <= 600);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);
  const galleryImages = [
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/DJI_0985.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-7.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-9.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-10.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-11.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-12.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-13.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-17.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-18.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-19.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-20.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-21.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-2.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-6.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-5.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-4.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-3.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-14.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-12.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-15.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-16.webp",
    "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-22.webp",
  ];

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const prevSlide = () => {
    setSlideNumber((prev) =>
      prev === 0 ? galleryImages.length - 1 : prev - 1
    );
  };

  const nextSlide = () => {
    setSlideNumber((prev) =>
      prev === galleryImages.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <div className="backgroundColor">
      <Helmet>
  <title>Ladakh Heights Hotel | Gallery</title>
  <meta
    name="description"
    content="Explore the gallery of Ladakh Heights Hotel, featuring breathtaking views of the Himalayas and luxury accommodations. Book your stay today and experience the beauty of Ladakh!"
  />
  <meta
    name="keywords"
    content="ladakh heights hotel, ladakh hotel, ladakh accommodations, ladakh luxury hotel, ladakh heights gallery, ladakh hotel images, ladakh photography, ladakh mountains, ladakh himalayas, ladakh travel, ladakh vacation, ladakh tourism, ladakh adventure"
  />
  <meta name="author" content="Your Name or Hotel Name" />
  <meta name="robots" content="index, follow" />
  {/* Add other meta tags as needed */}
</Helmet>
      <ScrollToTop />
      {openModal && (
        <div className="sliderWrap">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            onClick={handleCloseModal}
          />
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="btnPrev"
            onClick={prevSlide}
          />
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            className="btnNext"
            onClick={nextSlide}
          />
          <div className="fullScreenImage">
            <img src={galleryImages[slideNumber]} alt="" />
          </div>
        </div>
      )}

      <div className="roomsBodySuit">
        <div
          style={{
            backgroundImage: `url(${galleryImages[0]})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: isMobile ? "30vh" : "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="AboutUsHeadingImage"
        >
          <div className="AboutUsHeading">
            <p>LUXURY HOTEL EXPERIENCE</p>
            <h2
              className={isMobile ? "mobileHeadings" : ""}
              style={{ fontSize: "3vw", color: "white" }}
            >
              GALLERY
            </h2>
          </div>
        </div>
      </div>

      <div className="galleryWrap">
        {galleryImages.map((imageUrl, index) => (
          <div
            className="single"
            key={index}
            onClick={() => handleOpenModal(index)}
          >
            <LazyLoadImage src={imageUrl} loading="lazy" alt="" />
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default ViewGallery;
