



const AfroStyles = [
    {
      id: 1,
      title: "Model 1",
      description: "Wearing a crisp white shirt paired with perfectly fitted denim jeans and classic canvas",
      alt: "First Image",
      src: "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/DJI_0985.webp",
    },
    {
      id: 2,
      title: "Model 2",
      description: "Elevate your style with this Ankara long sleeve shirt and trouser",
      alt: "Second Image",
      src: "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-11.webp"
    },
    {
      id: 3,
      title: "Model 3",
      description: "Elevate your style with Ankara dresses.",
      alt: "Third Image",
      src: "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-12.webp"
    },
    {
      id: 4,
      title: "Model 3",
      description: "Elevate your style with Ankara dresses.",
      alt: "Third Image",
      src: "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-10.webp",
    },
   
  
  ]
  
  export default AfroStyles;