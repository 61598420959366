import React, { useState, useEffect } from 'react';
import SplashScreen from './component/Organisms/SplashScreen/SplashScreen';
import AllRoutes from './routes/publicRoutes/AllRoutes';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './component/Molecules/ScrollToTop/ScrollToTop';
import LandingPage from './component/Pages/LandingPage/LandingPage'; // Import your LandingPage component

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading by using setTimeout
    const fakeLoading = setTimeout(() => {
      setLoading(false);
    }, 2000); // Set the duration for your loading process

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(fakeLoading);
  }, []);

  

  return (
    <div>
      {/* {loading ? (
        <div>
          <SplashScreen />
        </div>
      ) : (
        <> */}
          <ScrollToTop />
          <div className='mainBackground'>
            <AllRoutes />
          </div>
        {/* </>
      )} */}
    </div>
  );
};

export default App;
