import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./BikeRental.css";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
const BikeRental = () => {
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [dropDate, setDropDate] = useState(null);
  const [dropTime, setDropTime] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleclick=()=>{
    if(selectedDate && selectedTime  && dropDate && dropTime)
    {

      console.log("click",open);
      setOpen(true);
      setTimeout(() => {
  
        setOpen(false);
    navigate("/rent-bike");

       
      }, 5000);
      console.log("click",open);
    }
    else{
      Swal.fire({
        title: "Field is empty",
        text: "Please select date and time to move further with booking",
        icon: "error"
      });

    }


  }
  const currentDate = new Date();
  return (
    <div>
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="backgroundImageBody">
        <div className="background-container">
          <div className="bikeCalanderBody">
            <div className="bikeCalanderBodyContainer">
              <div className="bikeCalanderBodyHeading">
                Search your next ride
              </div>
              <div className="bikeCalanderBodySubheading">Pick up</div>
              <div>
                <div className="rentalDateBody">
                  <div>
                    <DatePicker
                      className="rentalDate"
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Date"
                      minDate={currentDate}
                    />
                  </div>

                  <div>
                    <DatePicker
                      className="rentalDate"
                      selected={selectedTime}
                      onChange={(time) => setSelectedTime(time)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeFormat="HH:mm"
                      dateFormat="h:mm aa"
                      placeholderText="Time"
                    />
                  </div>
                </div>

                <div className="bikeCalanderBodySubheading">Drop off</div>

                <div className="rentalDateBody">
                <div>
                    <DatePicker
                      className="rentalDate"
                      selected={dropDate}
                      onChange={(date) => setDropDate(date)}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Date"
                      minDate={currentDate}
                    />
                  </div>

                  <div>
                    <DatePicker
                      className="rentalDate"
                      selected={dropTime}
                      onChange={(time) => setDropTime(time)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeFormat="HH:mm"
                      dateFormat="h:mm aa"
                      placeholderText="Time"
                    />
                  </div>
                </div>
                <button onClick={handleclick} className="rentalSubmitButton">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BikeRental;
