import React from 'react'
import pnf from "../../../assets/common/pnf.png"
import Footer from '../../Organisms/Footer/Footer'
import "./PageNotFound.css"

function PageNotFound() {
  return (
    <>
    <div className='pageNotfoundMain'>
        <p className='pageNotfoundMainPara1'>Page Not Found</p>
        <img src={pnf}></img>
        <p className='pageNotfoundMainPara2'><a href="/" style={{color:"#38B9AE"}}>Click here</a> to Go Back</p>
    </div>
    <Footer />
    </>
  )
}

export default PageNotFound