// Splash.js

import React from "react";
import LandingPage from "../../component/Pages/LandingPage/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "../../component/Organisms/Navbar/Navbar";
import ZoomSliders from "../../component/Pages/LandingPage/ZoomSliders";
import Carousel from "../../component/Pages/LandingPage/Carousel";
import Carousel1 from "../../component/Pages/LandingPage/carousel1";
import AboutUs from "../../component/Pages/AboutUs/AboutUs";
import Soon from "../../component/Organisms/ComingSoon/Soon";

import ContactUs from "../../component/Pages/ContactUs/ContactUsPage";
import RoomsAndSuits from "../../component/Pages/Room/RoomsAndSuits";
import Tariff from "../../component/Pages/Tariff/Tariff";
import ViewGallery from "../../component/Pages/Gallery/ViewGallery";
import TermsAndConditions from "../../component/Organisms/Footer/TermsAndConditions";
import PackageDetail from "../../component/Pages/Packages/PackageDetail";

import PacakgeMainPage from "../../component/Pages/Packages/PackageMainPage";
import AllPackages from "../../component/Pages/Packages/AllPackages";


import BikeRent from "../../component/Pages/BikeRent/BikeRent";

import Navbar2 from "../../component/Organisms/Navbar2/Navbar";
import BikeRental from "../../component/Pages/BikeRent/BikeRental";

const AllRoutes = () => {
  return (
    <BrowserRouter>
      {/* <Navbar /> */}
      <Navbar2/>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/zoom" element={<Carousel1 />} />
        <Route path="/packages" element={<AllPackages />} />
        <Route path="/bike-dashboard" element={<BikeRental />} />
        <Route path="/rent-bike" element={<BikeRent />} />
        <Route path="/coming-soon" element={<Soon/>} />
     




        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Room" element={<RoomsAndSuits />} />
        <Route path="/Tariff" element={<Tariff />} />
        <Route path="/Gallery" element={<ViewGallery />} />

        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/pp" element={<PackageDetail />} />
        <Route path="/packageDetail/:id" element={<PacakgeMainPage />} />


        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
