import React, { useEffect, useState } from 'react'
import AboutUsHeading from '../../../assets/4.jpeg'
import blackDot from '../../../assets/blackDot.png'

import { ErrorMessage, Field, Form, Formik } from 'formik'

import { Helmet } from 'react-helmet'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './BikeRent.css'

import Footer from '../../Organisms/Footer/Footer'
import Testimonal from '../../Pages/Testimonal/Testimonal'
// import FooterMobile from "../../Organisms/Footer/FooterMobile";
// import "./AboutUs.css";
import ScrollToTop from '../../Molecules/ScrollToTop/ScrollToTop'
import LandingPageGalleryCarousel from '../LandingPage/LandingPageGalleryCarousel'
import { useNavigate } from 'react-router-dom'

import package1 from '../../../assets/CAMP3.jpeg'
import package2 from '../../../assets/CAMPS.jpeg'
import package3 from '../../../assets/bike1.jpeg'
// import { Gallery } from "react-grid-gallery";

const packages = [
  {
    id: 1,
    packageName: 'Leh to Leh 06 Days trip',
    Price: '11,999',
    car: 'true',
    placesCover:
      'Leh – Shanti Stupa – Leh Palace – Magnetic Hill – Pathar Sahib Gurudwara – Hall of fame – Sangam Point – Disket – Hundar – Shayok – Durbuk – Pangong Tso – Leh',
    passesCover: 'Khardung La (17,982 ft) – Chang La (17,688 ft)',
    overview: [
      'Area – Ladakh',
      'Kilometer – 600 approx',
      'Duration – 04 Nights – 05 Days',
      'Max Altitude – 17,982 ft',
      'Number of Passes – 02',
      'Number of Lakes – 01',
      'Trip start – Leh Airport',
      'Trip End – Leh Airport',
      'Best time – May to September'
    ],
    accommodation: [
      '→Leh (3N)→Nubra Valley (1N) -> Pangong (1N)',
      'Leh : Himalayan Retreat karzoo / Similar',
      ' Nubra : Royal delux camp / Similar'
    ],
    itineary: [
      {
        question: 'Day 1: Arrival at Leh Airport',
        answer: [
          'Welcome to Ladakh, a unique land stretching from the Glacier to the main Great Himalayas.',
          'Your journey begins with your arrival at Leh Airport',
          'Leh, the capital and largest town of Ladakh, awaits you. The Golden Eagle Expedition Team will be there to greet you at the airport and escort you to your hotel for check-in.',
          'Today is designated for settling in and resting before we embark on exploring the breathtaking landscapes and roads.',
          'In the evening, our team leader will meet you at the hotel to provide a briefing about the trip.',
          'Overnight accommodation at a hotel in Leh.'
        ]
      },
      {
        question: 'Day 2: Leh Local Sightseeing towards Sham Valley',
        answer: [
          'Today’s sightseeing spans approximately 90 kilometers.',
          'Following a leisurely breakfast, we will explore Magnetic Hill, Pathar Sahib Gurudwara, and the Hall of Fame (a museum honoring courageous Indian soldiers).',
          'We will also visit Sangam Point, the meeting point of the Indus and Zanskar rivers.',
          'In the evening, we will take a trip to Shanti Stupa and Leh Palace.',
          'Overnight accommodation in Leh.'
        ]
      },
      {
        question: 'Day 3: Leh to Nubra Valley via Khardung-La Pass',
        answer: [
          'Travel distance and time: We will cover approximately 130 kilometers in about 5 to 6 hours.',
          'Our journey will take us through the majestic Khardung La Pass, standing at an impressive altitude of 17,982 feet, making it the highest motorable road.',
          'The valley we will traverse is renowned for its sand dunes adorned with white rolling sands, offering a captivating sight of the double-humped Bactrian Camels.',
          'At Diskit Monastery, a remarkable 33-meter statue of Maitreya Buddha overlooks the Shayok River.',
          'Nubra Valley presents numerous adventurous opportunities, including ATV bike rides, zip-lining, rafting, go-karting, and double-humped camel rides.',
          'Overnight accommodation awaits us at Swiss camps in Hunder.'
        ]
      },
      {
        question: 'Day 4: Nubra Valley to Leh via Pangong Lake',
        answer: [
          'Our journey takes us towards Leh via Pangong Lake, situated in the eastern part of Ladakh near the border with China.',
          'The travel distance covers approximately 360 kilometers and is expected to take around 12 to 13 hours.',
          'By afternoon, we will arrive at the lake, nestled amidst the mountains, offering a stunning surprise with its beauty.',
          'Take some time to cherish the serene surroundings.',
          'On our return journey to Leh, we will pass through Chang La, standing at an elevation of 5360 meters.',
          'Overnight accommodation awaits us in Leh.'
        ]
      },
      {
        question: 'Day 5: Departure for Home',
        answer: [
          'Following breakfast, we will depart for Leh Airport, marking the end of your journey with us. It is time for your departure.',
          'We sincerely hope that you carry with you joyful and vivid memories of this captivating and enigmatic landscape.',
          'We eagerly await the opportunity to welcome you back to Ladakh in the future.'
        ]
      }
    ]
  },
  {
    id: 2,
    packageName: 'Leh to Leh 06 Days trip',
    Price: '15,999',
    car: 'true',
    placesCover:
      'Leh – Shanti Stupa – Leh Palace – Magnetic Hill – Pathar Sahib Gurudwara – Hall of fame – Sangam Point – Disket – Hundar – Shayok – Durbuk – Pangong Tso – Leh',
    passesCover: 'Khardung La (17,982 ft) – Chang La (17,688 ft)',
    overview: [
      'Area – Ladakh',
      'Kilometer – 600 approx',
      'Duration – 04 Nights – 05 Days',
      'Max Altitude – 17,982 ft',
      'Number of Passes – 02',
      'Number of Lakes – 01',
      'Trip start – Leh Airport',
      'Trip End – Leh Airport',
      'Best time – May to September'
    ],
    accommodation: [
      '→Leh (3N)→Nubra Valley (1N)',
      'Leh : Hotel Mandala / Similar',
      ' Nubra : De Reeva Camp / Similar'
    ],
    itineary: [
      {
        question: 'Day 1: Leh (2 Nights)',
        answer: [
          'Guests arrive at Leh Airport according to their scheduled flight.',
          'They are met by the Veena World tour manager and transferred to the hotel.',
          'Free time is allotted for acclimatization upon arrival.',
          'In the evening, a short Meet & Greet session is organized to welcome the guests.'
        ]
      },
      {
        question: 'Day 2: Leh Sightseeing',
        answer: [
          "Visit Shey Palace, once the summer retreat of Ladakh's royal family.",
          'Explore Rancho School, also known as Druk White Lotus School.',
          'Discover Hemis Gompa, a prominent Himalayan Buddhist monastery, reputedly the wealthiest and largest in Leh.',
          'Take a photo stop at Thiksey Monastery.',
          "Witness Sindhu Darshan, a view of the Sindhu River, one of Asia's longest rivers.",
          'Explore the Hall of Fame War Museum, dedicated to the brave Indian soldiers who sacrificed their lives in Indo-Pak wars.',
          'Enjoy some free time for shopping later in the day.'
        ]
      },
      {
        question: 'Day 3: Leh – Khardung La Pass – Nubra Valley (1 Night)',
        answer: [
          'Depart for Nubra Valley via Khardung La, renowned as one of the world’s highest motorable passes.',
          'Nubra Valley, where the Shyok River meets the Siachen River, creating a vast valley that separates the Ladakh and Karakoram ranges.',
          'Upon arrival, visit Maitreya Buddha, a remarkable statue of Jampa Buddha perched atop a monastery. Admire its intricate carvings, brilliant craftsmanship, and vibrant colors.',
          'Explore Hunder Village and the Cold Desert, the exclusive habitat of twin-humped camels in India.',
          'Please note: Khardung La Pass is situated at a high altitude, which may pose challenges for guests with certain health conditions. It is advisable to consult with a doctor before planning your visit to ensure a safe and enjoyable experience.'
        ]
      },
      {
        question: 'Day 4: Nubra Valley – Pangong Tso (1 Night)',
        answer: [
          "Journey to Pangong Tso, also known as Pangong Lake, renowned as one of the world's highest saltwater lakes.",
          "Marvel at the lake's most notable feature: its ever-changing hues, which captivate visitors with their beauty.",
          'Explore the surroundings, as Pangong Lake serves as an important breeding ground for a diverse range of migratory birds, including Brahmani Ducks, Rare Black-necked Cranes, and Seagulls.',
          'Please note: Pangong Tso is situated at a high altitude, which may pose challenges for guests with certain health conditions. It is strongly advised to consult with a doctor before planning your visit to ensure a safe and enjoyable experience.'
        ]
      },
      {
        question: 'Day 5: Pangong Tso – Leh (2 Nights)',
        answer: [
          'Depart from Pangong Tso and head towards Leh via Changla Pass, renowned as one of the highest motorable roads in the world.',
          'The name "Changla Pass" translates to "Northern Pass" (Chang meaning north and La meaning Pass).',
          'Please note: Changla Pass is situated at a high altitude, which may present challenges for guests with certain health conditions. It is highly recommended to consult with your doctor before planning your visit to ensure a safe and enjoyable experience.'
        ]
      },
      {
        question: 'Day 6: Leh',
        answer: [
          'Visit Patthar Sahib Gurudwara, a beautiful Gurudwara Sahib built in memory of Guru Nanak.',
          'Explore Magnetic Hill, also known as Gravity Hill, where vehicles appear to defy the force of gravity.',
          'Witness the Sangam of the rivers Indus & Zanskar, where the two great Himalayan rivers merge, offering a spectacular view.',
          'Visit Shanti Stupa, constructed by Japanese monks to promote world peace.',
          'In the evening, enjoy a Folk dance show.'
        ]
      },
      {
        question: 'Day 7: Leh - Departure',
        answer: [
          'Bid farewell to travel companions and exchange contact information for staying in touch through email, phone, WhatsApp, Facebook, and Instagram.',
          'Guests will proceed to Leh airport as per their scheduled flight and begin their return journey back home.',
          'Tour concludes.',
          'Please note: Guests must adhere to mandatory travel requirements such as RT-PCR tests, vaccination certificates, web check-in, self-declaration, e-pass formalities, etc., as per the state/country regulations, subject to periodic changes. Any costs incurred for such requirements are to be borne by the guests.',
          'Transportation for transfers and sightseeing will be arranged based on the respective tour group size, using small cars, Tempo Travellers, or mini/big coaches. In small cars, there will be 4-6 guests per car. Guests can opt for exclusive vehicle usage at an additional cost, subject to availability.',
          'Due to the current dynamic situation, some monuments or sightseeing places may be temporarily closed without prior notice. Every effort will be made to visit alternate sightseeing spots if possible.'
        ]
      }
    ]
  },
  {
    id: 3,
    packageName: 'Leh to Leh 07 Days trip',
    Price: '29,999',
    car: 'true',
    placesCover:
      'Leh – Shanti Stupa – Leh Palace – Magnetic Hill – Pathar Sahib Gurudwara – Hall of fame – Sangam Point – Disket – Hundar – Shayok – Durbuk – Pangong Tso – Leh',
    passesCover: 'Khardung La (17,982 ft) – Chang La (17,688 ft)',
    overview: [
      'Area – Ladakh',
      'Kilometer – 600 approx',
      'Duration – 04 Nights – 05 Days',
      'Max Altitude – 17,982 ft',
      'Number of Passes – 02',
      'Number of Lakes – 01',
      'Trip start – Leh Airport',
      'Trip End – Leh Airport',
      'Best time – May to September'
    ],
    accommodation: [
      '→Leh (3N)→Nubra Valley (1N)',
      'Leh : Hotel Mandala / Similar',
      ' Nubra : De Reeva Camp / Similar'
    ],
    itineary: [
      {
        question: 'Day 1 Arrival in Leh Airport',
        answer: [
          'Welcome to Ladakh extending from the Glacier to the main Great Himalayas, Ladakh is a land like no other',
          'Your trip starts with your arrival at Leh Airport.',
          'Leh is capital and largest town of Ladakh, Golden Eagle Expedition Team will meet you at the airport and drive you to the hotel to check in.',
          'Today is set aside to allow you to settle in and rest up before we start explore stunning landscapes and roads.',
          'By evening our supported team leader will meet you at the hotel and brief you about Trip.',
          'Overnight stay at hotel in Leh'
        ]
      },
      {
        question: 'Day 1 Arrival in Leh Airport',
        answer: [
          'Welcome to Ladakh extending from the Glacier to the main Great Himalayas, Ladakh is a land like no other',
          'Your trip starts with your arrival at Leh Airport.',
          'Leh is capital and largest town of Ladakh, Golden Eagle Expedition Team will meet you at the airport and drive you to the hotel to check in.',
          'Today is set aside to allow you to settle in and rest up before we start explore stunning landscapes and roads.',
          'By evening our supported team leader will meet you at the hotel and brief you about Trip.',
          'Overnight stay at hotel in Leh'
        ]
      },
      {
        question: 'Day 1 Arrival in Leh Airport',
        answer: [
          'Welcome to Ladakh extending from the Glacier to the main Great Himalayas, Ladakh is a land like no other',
          'Your trip starts with your arrival at Leh Airport.',
          'Leh is capital and largest town of Ladakh, Golden Eagle Expedition Team will meet you at the airport and drive you to the hotel to check in.',
          'Today is set aside to allow you to settle in and rest up before we start explore stunning landscapes and roads.',
          'By evening our supported team leader will meet you at the hotel and brief you about Trip.',
          'Overnight stay at hotel in Leh'
        ]
      },
      {
        question: 'Day 1 Arrival in Leh Airport',
        answer: [
          'Welcome to Ladakh extending from the Glacier to the main Great Himalayas, Ladakh is a land like no other',
          'Your trip starts with your arrival at Leh Airport.',
          'Leh is capital and largest town of Ladakh, Golden Eagle Expedition Team will meet you at the airport and drive you to the hotel to check in.',
          'Today is set aside to allow you to settle in and rest up before we start explore stunning landscapes and roads.',
          'By evening our supported team leader will meet you at the hotel and brief you about Trip.',
          'Overnight stay at hotel in Leh'
        ]
      },
      {
        question: 'Day 1 Arrival in Leh Airport',
        answer: [
          'Welcome to Ladakh extending from the Glacier to the main Great Himalayas, Ladakh is a land like no other',
          'Your trip starts with your arrival at Leh Airport.',
          'Leh is capital and largest town of Ladakh, Golden Eagle Expedition Team will meet you at the airport and drive you to the hotel to check in.',
          'Today is set aside to allow you to settle in and rest up before we start explore stunning landscapes and roads.',
          'By evening our supported team leader will meet you at the hotel and brief you about Trip.',
          'Overnight stay at hotel in Leh'
        ]
      }
    ]
  }
]

function AllPackages () {
  const navigate = useNavigate()

  const handleClick = id => {
    console.log('id', id)
    navigate(`/packageDetail/${id}`, { state: packages })
  }
  const carouselData = [
    {
      img: package1,
      title: 'Leh to leh 5N/6D',
      text: 'Leh (2N) - Nubra (1N) – Pangong-Tso (1N) – Leh (1N)  ',
      realPrice: '28000',
      discountPrice: '22000'
    },
    {
      img: package2,
      title: 'Leh to leh 6N/7D',
      text: 'Discover the ladakh on bike',
      realPrice: '28000',
      discountPrice: '39000'
    },
    {
      img: package3,
      title: 'Leh to leh 5N/6D',
      text: 'Discover the ladakh on bike',
      realPrice: '28000',
      discountPrice: '24000'
    },
    {
      img: 'https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-13.webp',
      title: 'Leh to leh 5N/6D',
      text: 'Discover the ladakh on bike',
      realPrice: '28000',
      discountPrice: '44000'
    }
  ]
  const [isMobile, setIsMobile] = useState(false)

  const [minPrice, setMinPrice] = useState('20000')
  const [maxPrice, setMaxPrice] = useState('29000')
  const [filteredData, setFilteredData] = useState(carouselData)

  const handleFilter = () => {
    const filtered = carouselData.filter(item => {
      const price = parseInt(item.discountPrice)
      return price >= minPrice && price <= maxPrice
    })
    console.log('filter', filtered)
    setFilteredData(filtered)
  }
  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window.outerWidth])
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>About Ladakh Travel | Luxury Boutique Retreat in Ladakh</title>
        <meta
          name='description'
          content='Discover the allure of Ladakh Heights, a luxury boutique retreat located just 4kms from the bustling market. Enjoy captivating panoramas, spacious rooms, and traditional aesthetics amidst breathtaking vistas. Book your stay now!'
        />
        <meta
          name='keywords'
          content='ladakh heights, ladakh hotel, ladakh boutique hotel, ladakh luxury hotel, ladakh retreat, ladakh accommodations'
        />
        <meta name='robots' content='index, follow' />
      </Helmet>

      {isMobile ? (
        <></>
      ) : (
        <>
          <div className='AboutUsBody'>
            <div
              style={{
                backgroundImage: `url(${AboutUsHeading})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: "100%",
                backgroundSize: 'cover', // Ensures the image covers the entire container
                backgroundPosition: 'center', // Centers the image within the container
                width: '100%', // Set the desired width for your container
                height: '40vh', // Set the desired height for your container
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className='AboutUsHeadingImage'
            >
              {/* <div className="img-mask"></div> */}

              <div className='AboutUsHeading'>
                {/* <p>LUXURY HOTEL EXPERIENCE</p> */}
                <h2 style={{ fontSize: '3vw' }}>Bike Rent</h2>
              </div>
            </div>
            <div className='allPackagesContentBody'>
              <h3 className='allPackagesHeading1'>
                "Unmatched Leh Ladakh Tour Packages: Your Gateway to Adventure"
              </h3>
              <p className='allPackagesHeading2'>
                Explore our top-notch Leh Ladakh tour packages for an
                unforgettable journey. We offer hassle-free trips for both bike
                and car enthusiasts, ensuring peace of mind throughout your
                adventure. Benefit from the best rates available and embark on
                an incredible expedition with us.
              </p>
            </div>

            <div className='allPackagesGrid'>
              {filteredData.map((service, index) => (
                <>
                  <div
                    key={index}
                    // onClick={() => handleClick(index)}
                    class='card'
                    style={{ width: '25rem' }}
                    //   style='width: 18rem;'
                  >
                    <img src={service.img} class='card-img-top' alt='...' />

                    <div class='card-body'>
                      <p className='bikeAvailable'>Available at</p>
                      <Formik
                        initialValues={{
                          location: ''
                        }}
                        enableReinitialize={true}
                        // validationSchema={loginSchema}
                        // onSubmit={handleSubmit}
                      >
                        {
                          <Form>
                            <label style={{ width: '100%' }}>
                              <Field
                                as='select'
                                name='location'
                                placeholder='Location '
                                className='inputFieldBooking'
                              >
                                <option value='' disabled selected>
                                  Location
                                </option>
                                <option>Changspa Road</option>
                                <option>Lower tukcha</option>
                                <option>Deliver to hotel</option>
                              </Field>
                              <ErrorMessage
                                className='errorMessageModalValidation'
                                name='location'
                                component='div'
                              />
                            </label>
                          </Form>
                        }
                      </Formik>

                      {/* <p
                        style={{ fontSize: '1vw', color: 'grey' }}
                        class='card-text'
                      >
                        {service.text}
                      </p> */}
                    </div>
                    {/* <hr /> */}
                    <div className='bookingBlock'>
                      <div className='pickUpBookingTime'>
                        <div className='bookingTimeBike'>9:00am</div>
                        <div className='bookingDateBike'>23 match </div>
                      </div>
                      <div
                        style={{
                          backgroundImage: `url(${blackDot})`,
                          backgroundRepeat: 'no-repeat',
                          // backgroundSize: "100%",
                          backgroundSize: 'cover', // Ensures the image covers the entire container
                          backgroundPosition: 'center', // Centers the image within the container
                          width: '15%', // Set the desired width for your container
                          height: 'auto', // Set the desired height for your container
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white'
                        }}
                      >
                        To
                      </div>
                      <div className='dropOffBookingTime'>
                        <div className='bookingTimeBike'>10:00am</div>
                        <div className='bookingDateBike'>27 match </div>
                      </div>
                    </div>
                    <hr />

                    <div className='bookingButtonBlock'>
                      <div className='bikePrice'>
                        <div className='bookingTimeBike'>Rs. 1900</div>
                        <div className='bookingDateBike'>
                          (180 km included){' '}
                        </div>
                      </div>
                      <div>
                        <button className='bookingButtonBike'>
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ))}
              <button onClick={handleFilter}>filter</button>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}

export default AllPackages
