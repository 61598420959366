import React, { useContext, useState } from "react";
import myAccountIcon1 from "../../../assets/MyAccount/myAccountIcon1.png";
import myAccountIcon3 from "../../../assets/MyAccount/myAccountIcon3.png";
import myAccountIcon4 from "../../../assets/MyAccount/myAccountIcon4.png";
import myAccountIcon2 from "../../../assets/MyAccount/myAccountIcon2.png";

import nm7 from "../../../assets/MyAccount/nm7.png";
import "./Hamburger.css";
// import { UserContext } from "../../../App";

function Hamburger(props) {
  // const role = useContext(UserContext);

  const [islogin, setlogin] = useState(false);
  const [ans1, setAns1] = useState(false);
  const [ans, setAns] = useState(false);
  const handleAns1 = () => setAns1(!ans1);
  const handleAns = () => setAns(!ans);
  const handleLogout = () => {
 
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleClose = () => {
    props.open(false);
  };

  return (
    <div className="menuBody">
      <div className="lists">
        <div className="MenuClose">
          <a href="/">
            <li className="menuBtns1" >
              Home
            </li>
          </a>
          <div style={{ marginTop: "1.7em" }} onClick={handleClose}>
            x
          </div>
        </div>
       
        <a>
          <li className="menuBtns" onClick={handleAns}>
            Hotels
          </li>
        </a>
        {ans && (
          <>
            <a onClick={() => openInNewTab('https://ladakheights.com/')}>
              <li className="menuBtns2">
               Ladakh Heights Hotel Leh
              </li>
            </a>
            <a onClick={() => openInNewTab('https://www.hotelhimalayanretreat.com/')}>
              <li className="menuBtns2" >
                Himalayan Retreat karzoo Leh
              </li>
            </a>
            <a onClick={() => openInNewTab('https://www.royaldeluxecamp.in/')}>
              <li className="menuBtns2" >
               Royal Delux Camp Nubra
              </li>
            </a>
           
           
          
          </>
        )}
        <a href="/Packages">
          <li className="menuBtns" >
          Packages
          </li>
        </a>
        <a href="/coming-soon" >
          <li className="menuBtns">
           Trekkings
          </li>
        </a>
        <a href="/coming-soon">
          <li className="menuBtns" >
            Rent Bike
          </li>
        </a>
        <a href="/coming-soon">
          <li className="menuBtns" >
            Book Taxi
          </li>
        </a>
        <a>
          <li className="menuBtns3" onClick={handleAns1}>
            Company
          </li>
        </a>
        {ans1 && (
          <>
            <a href="/AboutUs">
              <li className="menuBtns2" >
                About Us
              </li>
            </a>
            <a href="/ContactUs">
              <li className="menuBtns2" >
                Contact Us
              </li>
            </a>
            
          </>
        )}
        <div className="navLine">
          _____________________________________
        </div>
        <div className="menuBtnsName">Ladakh's Premier Women-Led Travel Agency!</div>

        {/* {true? (
          <>
            <div className="MblNavigationBody">
              <a
                className="dropdown-itemMbl"
                href="/account"
              >
                {" "}
                <img className="iconProfile" src={myAccountIcon1}></img>
                My Account
              </a>
              <a
                className="dropdown-itemMbl"
                href="/account/health-risk-assessment-report"
              >
                {" "}
                <img className="iconProfile" src={myAccountIcon3}></img>
                My HRA Reports
              </a>{" "}
              <a
                className="dropdown-itemMbl"
                href="/MyDiagnosticReport"
              >
                {" "}
                <img className="iconProfile" src={myAccountIcon3}></img>
                My Diagnostic Reports
              </a>{" "}
              <a
                className="dropdown-itemMbl"
                href="/account/prescription"
              >
                {" "}
                <img className="iconProfile" src={myAccountIcon4}></img> My
                Prescriptions
              </a>
              <a
                className="dropdown-itemMbl"
                href="/order-history-packages"
              >
                {" "}
                <img className="iconProfile" src={myAccountIcon3}></img>
                Purchase History
              </a>
              <a
                className="dropdown-itemMbl"
                href="/order-history-subscriptions"
              >
                {" "}
                <img className="iconProfile" src={myAccountIcon2}></img>
                My Subscription
              </a>
              <a
                className="dropdown-itemMbl"
                href="/order-history-program"
              >
                {" "}
                <img className="iconProfile" src={myAccountIcon2}></img>
                My Corporate Program
              </a>
            
            </div>
            <div
              style={{ marginTop: "4vw", marginBottom: "-1vw" }}
              className="navLine"
            >
              _______________________________
            </div>

            <a>
              <li className="menuBtnss" onClick={handleLogout}>
                <img className="iconNave" src={nm7}></img> Logout
              </li>
            </a>
          </>
        ) : (
          <div className="loginNregister">
            <a href="/login">
              <li className="menuBtnss">
                <img className="iconNave" src={nm7}></img> Login
              </li>
            </a>
            <li className="menuBtnss1"> or </li>
            <a href="/register">
              <li className="menuBtnss"> Register</li>
            </a>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default Hamburger;
