import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";

import Copyright from "../../../assets/common/copyright.png";
import Email from "../../../assets/common/email.png";
import Instagram from "../../../assets/common/instagram.png";
import Logo from "../../../assets/whats.png";
import ALogo from "../../../assets/journeythroughladakh.png";


import Phone from "../../../assets/common/phone.png";
import "./FooterMobile.css";
import emailWhite from "../../../assets/emailWhite.png";

function FooterMobile() {
  return (
    <>
    <div className="footerMobile">
      <div className="upperChild2FooterMobile">
       
        <ol>
        <Link  to="/AboutUs">

        
            <li>About Us</li>
          </Link>
        <Link  to="/terms-and-conditions">
          
          
            <li>Terms and Conditions</li>
          </Link>
         
        <Link  to="/ContactUs">

        
            <li>Contact Us</li>
          </Link>
        </ol>
      </div>
  
      <div className="upperChild4Mobile">
        <div className="upperChild4Heading">
          <p>Newsletter</p>
        </div>

        <div className="upperChild4DescriptionMobile">
          <span>
            Signup our newsletter to get update on information, news, insights
            and promotions.
          </span>
        </div>
        <div className="footerContact">
          <Formik
            initialValues={{
              Email: "",
            }}
            enableReinitialize={true}
          >
            
            {
              <Form>
                  <div className="logoImageMobile1">
                  <Link  to="/">

            {" "}
            <img alt="Logo" src={ALogo} />
          </Link>
        </div>
                <div className="emailInputMobile">
                  <Field
                    placeholder=" Email"
                    name="Email"
                    type="text"
                    className="newsLetterInput"
                  ></Field>
                  <ErrorMessage name="Name" component="div" />
                </div>
                <button className="signupFooterMobile" type="submit">
                  <img alt="Email" src={emailWhite} />
                  Sign-up
                </button>
              
              </Form>
            }
          </Formik>
        </div>
      </div>
      <div className="upperChild1Mobile">
       
        <div className="addressFooterMobile">

        </div>
        <div className="addressFooterMobile">
          <p>
          Gonpa gangles, Lamdon Road  <br />
                  Ladakh <br />
                  Leh - 194101
          </p>
        </div>
        <div className="emailContact">
          <img alt="Email" src={Email} />
          <a href="mailto: support@ladakheights.com">
            <p style={{ color: " #1D2334" }}>support@journeythroughladakh.com</p>{" "}
          </a>
        </div>
        <div className="phoneContact">
          <img alt="Phone" src={Phone} />
          <p>+91 9906697810 </p>
                <p>+91 6005537169 </p>
        </div>

        <div className="socialmediaContactMobile">
          <a href="https://www.instagram.com/journeythroughladakh?igsh=MW1zNDIyY3kyZHJvNA%3D%3D&utm_source=qr" target="_blank">
            <img alt="Instagram" src={Instagram} />
          </a>
         
        </div>
       
      </div>
      <div className="downFooterMobile">
        <div className="copyrightSign">
          <img alt="Copyright" src={Copyright} />
        </div>
        <div className="copyrightText">
          <p>Journey Through Ladakh - 2024. All right reserved..</p>

        </div>
      </div>
      <div class="fixed-bottom right-100 p-4" style={{zIndex:"6", left:"initial"}}>
        <a href="https://wa.me/919906697810?text=Hello How can I help you?" target="_blank">
          <img src={Logo} width="60" alt="aaaa"/>

        </a>

      </div>
     
    </div>

   
    </>
  );
}

export default FooterMobile;
