import React, { useEffect, useState } from 'react'
import AboutUsHeading from '../../../assets/jig2.jpeg'
import pic1 from '../../../assets/jig3.jpeg'

import pic2 from '../../../assets/jig1.jpeg'

// import AboutUsHeading from '../../../assets/4.jpeg'

import { Helmet } from 'react-helmet'

import Footer from '../../Organisms/Footer/Footer'
import Testimonal from '../../Pages/Testimonal/Testimonal'
// import FooterMobile from "../../Organisms/Footer/FooterMobile";
import './AboutUs.css'
import ScrollToTop from '../../Molecules/ScrollToTop/ScrollToTop'
import LandingPageGalleryCarousel from '../LandingPage/LandingPageGalleryCarousel'

// import { Gallery } from "react-grid-gallery";

function AboutUs () {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window.outerWidth])
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>About Ladakh Travel | Luxury Boutique Retreat in Ladakh</title>
        <meta
          name='description'
          content='Discover the allure of Ladakh Heights, a luxury boutique retreat located just 4kms from the bustling market. Enjoy captivating panoramas, spacious rooms, and traditional aesthetics amidst breathtaking vistas. Book your stay now!'
        />
        <meta
          name='keywords'
          content='ladakh heights, ladakh hotel, ladakh boutique hotel, ladakh luxury hotel, ladakh retreat, ladakh accommodations'
        />
        <meta name='robots' content='index, follow' />
      </Helmet>

      {isMobile ? (
        <>
          <div className='AboutUsBody'>
            <div
              style={{
                backgroundImage: `url(${AboutUsHeading})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: "100%",
                backgroundSize: 'cover', // Ensures the image covers the entire container
                backgroundPosition: 'center', // Centers the image within the container
                width: '100%', // Set the desired width for your container
                height: '30vh', // Set the desired height for your container
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className='AboutUsHeadingImage'
            >
              {/* <div className="img-mask"></div> */}

              <div className='AboutUsHeading'>
                {/* <p>LUXURY HOTEL EXPERIENCE</p> */}
                <h2 className='mobileHeadings' style={{ fontSize: '3vw' }}>
                  About Journey Through Ladakh
                </h2>
              </div>
            </div>

            <div>
              <div className='landingSecondPageGridMobile'>
                <div>
                  <div
                    style={{
                      backgroundImage: `url(${pic1})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                    }}
                    className='aboutUsPageImg1'
                  ></div>
                  {/* <img className="landingSecondPagePic2" alt="error" src={h2}></img> */}
                  <div
                    style={{
                      backgroundImage: `url(${pic2})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                    }}
                    className='aboutUsPageImg2'
                  ></div>
                </div>

                <div className='aboutUsContentBody'>
                  <div className='landingSecondPageHeading'>
                    {' '}
                    Journey Through Ladakh
                  </div>
                  <div className='landingSecondPageSubHeading1'>
                    Discover the Essence of Ladakh: Our Story
                  </div>

                  {/* <div className="landingSecondPageSubHeading2">
                  Welcome to Ladakh Heights
                </div> */}

                  <div className='landingSecondPageContent'>
                  
                    Nestled amidst the rugged beauty of Ladakh, Kunzes Angmo
                    discovered her true calling. Driven by an innate passion for
                    the mountains and an adventurous spirit, Kunzes embarked on
                    a transformative journey that would shape the way people
                    experience travel in the region.
                    <br />
                    <br />
                    From her humble beginnings trekking alongside her father to
                    evolving into a seasoned guide, Kunzes's love for adventure
                    paved the way for the establishment of Journey Through
                    Ladakh, Ladakh's Premiere Women Led Travel Agency. With in
                    depth knowledge of Ladakh's rich culture and history, Kunzes
                    Kunzes offers travelers immersive experiences unlike any
                    other.
                    <br />
                    <br />
                    Driven by a desire to make a lasting impact, Kunzes expanded
                    her horizons, introducing luxury accommodations such as the
                    acclaimed Swiss camp in Nubra Valley. Her innovative mindset
                    led to the launch of Ladakh's first woman-led international
                    outbound tour, showcasing the region's splendor to the
                    global audience.
                    <br />
                    <br />
                    Now, Kunzes's vision reaches new heights with the
                    forthcoming Ladakh Heights luxury hotel in Leh. With
                    Kunzes's steadfast dedication to hospitality and
                    awe-inspiring vistas, Ladakh Heights promises to redefine
                    the travel landscape in Ladakh.
                    <br />
                    <br />
                    Kunzes's journey stands as a testament to the power of
                    determination and the joy of exploration.
                    <br />
                    <br />
                    
                  </div>

                  <div className='landingSecondPageSubHeading3'>
                    Join us as we continue to inspire and embark on adventures
                    that transcend boundaries. Welcome to Journey Through
                    Ladakh.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {/* <ReserveForm /> */}
          {/* <ViewGallery  /> */}
          {/* <Testimonal /> */}
          {/* <Map /> */}
        </>
      ) : (
        <>
          <div className='AboutUsBody'>
            <div
              style={{
                backgroundImage: `url(${AboutUsHeading})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: "100%",
                backgroundSize: 'cover', // Ensures the image covers the entire container
                backgroundPosition: 'center', // Centers the image within the container
                width: '100%', // Set the desired width for your container
                height: '60vh', // Set the desired height for your container
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className='AboutUsHeadingImage'
            >
              {/* <div className="img-mask"></div> */}

              <div className='AboutUsHeading'>
                {/* <p>LUXURY HOTEL EXPERIENCE</p> */}
                <h2 style={{ fontSize: '3vw' }}>About Journey Through Ladakh </h2>
              </div>
            </div>

            <div>
              <div className='landingSecondPageGrid'>
                <div>
                  <div
                    style={{
                      backgroundImage: `url(${pic1})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                    }}
                    className='aboutUsPageImg11'
                  ></div>
                  {/* <img className="landingSecondPagePic2" alt="error" src={h2}></img> */}
                  <div
                    style={{
                      backgroundImage: `url(${pic2})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                    }}
                    className='aboutUsPageImg22'
                  ></div>
                </div>

                <div className='aboutUsContentBody'>
                  <div className='landingSecondPageHeading'>
                    {' '}
                    Journey Through Ladakh
                  </div>
                  <div className='landingSecondPageSubHeading1'>
                    Where Experience Meets Adventure: Explore Ladakh with
                    Confidence!
                  </div>

                  {/* <div className="landingSecondPageSubHeading2">
                    Welcome to Ladakh Heights
                  </div> */}

                  <div className='landingSecondPageContent'>
                    Once upon a time, amidst the rugged beauty of Ladakh, there
                    lived a woman named Kunzes Angmo. From a young age, Kunzes
                    was drawn to the majestic mountains that surrounded her
                    home. She would often accompany her father, a seasoned
                    guide, on treks through the Himalayas, learning the secrets
                    of the land with each step.
                    <br />
                    <br />
                    As she grew older, Kunzes's passion for adventure only
                    intensified. She became a guide herself, leading travelers
                    from all over the world on breathtaking journeys through
                    Ladakh's remote valleys and towering peaks. With her deep
                    knowledge of the region's culture and history, Kunzes was
                    able to offer her guests a truly immersive experience unlike
                    any other.
                    <br />
                    <br />
                    But Kunzes dreamed of more. She wanted to create something
                    that would leave a lasting impact on Ladakh's tourism
                    industry. And so, she took a leap of faith and established
                    her own travel agency – the first woman-led agency in all of
                    Ladakh.
                    <br />
                    <br />
                    With her unwavering determination and tireless work ethic,
                    Kunzes transformed her agency into a thriving business,
                    offering a wide range of tours and treks to suit every
                    adventurer's needs. Her reputation grew, and soon travelers
                    from far and wide were flocking to Ladakh to experience the
                    magic of Kunzes's tours firsthand.
                    <br />
                    <br />
                    But Kunzes's ambitions didn't stop there. In 2010, she
                    decided to venture into hospitality and opened a Swiss camp
                    in the enchanting Nubra Valley. The camp quickly became a
                    beloved destination for travelers seeking a luxurious yet
                    authentic Ladakhi experience.
                    <br />
                    <br />
                    Never one to rest on her laurels, Kunzes continued to push
                    boundaries. In a bold move, she launched the first woman-led
                    international outbound tour from Ladakh, showcasing the
                    region's beauty to the world.
                    <br />
                    <br />
                    And now, Kunzes is embarking on her most ambitious project
                    yet – the construction of Ladakh Heights, a luxury hotel in
                    the heart of Leh. With its stunning views and unparalleled
                    hospitality, Kunzes's hotel promises to elevate the travel
                    experience in Ladakh to new heights.
                    <br />
                    <br />
                  </div>

                  <div className='landingSecondPageSubHeading3'>
                    As Kunzes looks out over the mountains that have always been
                    her home, she knows that her journey is far from over. With
                    each new endeavor, she continues to inspire others to follow
                    their dreams and explore the world around them. And in the
                    end, isn't that what adventure is all about?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {/* <ReserveForm /> */}
          {/* <ViewGallery  /> */}
          {/* <Map /> */}
        </>
      )}
    </>
  )
}

export default AboutUs
