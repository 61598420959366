import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { CardContent } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
// import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import SessionId from "../../../Utils/sessionId";
// import storage from "../../../Utils/storage";
import dot from "../../../assets/Health_Checkups_Assests/dot.png";
import downArrow from "../../../assets/Package/DownArrow.png";
import group1 from "../../../assets/Package/Group1.png";
import group2 from "../../../assets/Package/Group2.png";
import group3 from "../../../assets/Package/Group3.png";
import group4 from "../../../assets/Package/Group4.png";
import Package from "../../../assets/Package/package_bg.png";
import upArrow from "../../../assets/Package/upArrow.png";

// import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import Footer from "../../Organisms/Footer/Footer";
import "./PackageDetail.css";
import PageNotFound from "../../Pages/PageNotFound/PageNotFound";
import Title from "../../Atoms/Title/Title";

function PackageDetail() {
  const { slug } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    }
  }, [window.outerWidth]);

  const [selectedCollapse, setSelectedCollapse] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const packageDetails=[
    {
      parameterCount:"2",
      attributeName:"name",
      parameters:[{parameterName:"name"},
      {parameterName:"name"},
      {parameterName:"name"},
      {parameterName:"name"},
      {parameterName:"name"},
    ],
      description:"hiello description",
      totalTest:"2",
      packageName:"namePcakhe",
      packageCost:"200",
      discountedPackageCost:"100",
      maxReportTime:"300:30",
      prerequisites:"200",


    },
    {
      parameterCount:"2",
      attributeName:"name",
      parameters:[{parameterName:"name"}],
      description:"hiello description",
      totalTest:"2",
      packageName:"namePcakhe",
      packageCost:"200",
      discountedPackageCost:"100",
      maxReportTime:"300:30",
      prerequisites:"200",


    },
    {
      parameterCount:"2",
      attributeName:"name",
      parameters:[{parameterName:"name"}],
      description:"hiello description",
      totalTest:"2",
      packageName:"namePcakhe",
      packageCost:"200",
      discountedPackageCost:"100",
      maxReportTime:"300:30",
      prerequisites:"200",


    },
    {
      parameterCount:"2",
      attributeName:"name",
      parameters:[{parameterName:"name"}],
      description:"hiello description",
      totalTest:"2",
      packageName:"namePcakhe",
      packageCost:"200",
      discountedPackageCost:"100",
      maxReportTime:"300:30",
      prerequisites:"200",


    },
  ]

  const plans = [
    {
      Name: "Qualified and Experienced Medical Professionals",
      Image: group1,
    },
    {
      Name: "Convenient Home Sample Collection",
      Image: group2,
    },
    {
      Name: "Quick Turnaround Time",
      Image: group3,
    },
    {
      Name: "Affordable Prices",
      Image: group4,
    },
  ];
  // function PackageDetail(props) {
  const dtoList =
    localStorage.getItem("itemDtoList") !== null
      ? JSON.parse(localStorage.getItem("itemDtoList"))
      : [];
  // const dispatch = useDispatch();
  const uuid = slug?.slice(slug.length - 36);
  const handleClick = (index) => {
    if (
      packageDetails[index]
        .parameterCount === 0
    ) {
      setSelectedCollapse(null);
    } else {
      setSelectedCollapse(index === selectedCollapse ? null : index);
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    // dispatch(packageDetailsRequest(uuid));
  }, []);

  const handleAddCart = (item) => {
    // SessionId();
    // const finalItem = {
    //   count: 1,
    //   name: item.packageName,
    //   productId: item.packageUuid,
    //   productType: "PACKAGE",
    // };
    // dtoList.push(finalItem);
    // localStorage.setItem("itemDtoList", JSON.stringify(dtoList));
    // if (storage.getStorage("token") === null) {
    //   dispatch(createCartGuestRequest(dtoList));
    // } else {
    //   dispatch(createCartLoginRequest(dtoList));
    // }
    // setTimeout(() => {
    //   window.location.reload();
    // }, 600);
  };

  // const packageDetails = useSelector((state) => state.package.packageDetails);
  
  // if (packageDetails.isLoading) {
  //   return <YuvaLoader />;
  // }
  return (
    <>
      <Title title="YUVA | Health package details" />

      {true ? (
        <>
          {/* <ScrollToTop /> */}
          {isMobile ? (
            <>
              {true ? (
                <div>
                  <div className="">
                    <div className="CareerHeadingImagePackage">
                      <div className="PrimeComprehensiveFlex">
                        <div className="PrimeComprehensive font_FamilyA">
                        Package name
                        </div>
                        {/* <button className="PrimeComprehensive_Button">Add</button> */}
                      </div>
                      <div className="PrimeComprehensivePriceDisplay">
                        <div className="font_FamilyA PrimeComprehensivePrice">
                          <span
                            className="FHCSection2_Children2OldPriceMobile"
                            style={{ color: " #A93C3C", width: "40%" }}
                          >
                           Cost
                          </span>{" "}
                          &nbsp; ₹ discountCost/-

                        </div>
                      </div>

                      <div className="HomeSampleContainerLongParaTextM ">
                        <div className="HomeSampleContainerParaM">
                          Home Sample Collection | Report Available within{" "}
                          {/* {packageDetails?.data?.data?.data.data.maxReportTime}{" "} */}
                          hrs | Unisex - 0 yrs - 99 yrs |{" "}
                          {/* {packageDetails?.data?.data?.data.data.prerequisites} */}
                        </div>
                      </div>
                    </div>

                    <div className="PackageDetailContainer">
                      <span className="About_PackageMainHeading font_FamilyA">
                        About the Package
                      </span>
                      <div className="About_PackageSubHeading font_FamilyA">
                        {packageDetails.description}
                      </div>
                      <br />
                    
                      <span className="About_PackageMainHeading font_FamilyA">
                        {packageDetails.totalTest} Lab
                        Tests in this package
                      </span>

                      <div className="About_PackageMainHeadingLabGrid">
                        <Box>
                          <CardContent>
                            {packageDetails.map(
                              (itm, index) => (
                                <>
                                  <div
                                    className={
                                      selectedCollapse === index
                                        ? "package-card-headnew"
                                        : "package-card-head"
                                    }
                                    onClick={() => handleClick(index)}
                                    style={
                                      itm.parameterCount !== 0
                                        ? { cursor: "pointer" }
                                        : { cursor: "not-allowed" }
                                    }
                                  >
                                    <Collapsible
                                      className={
                                        selectedCollapse === index
                                          ? "Collapsiblen2"
                                          : "Collapsiblen1"
                                      }
                                      triggerDisabled={
                                        itm.parameterCount === 0 ? true : false
                                      }
                                      trigger={`${itm.attributeName}  ${itm.parameterCount === 0
                                        ? ""
                                        : `-  ${itm.parameterCount} Tests`
                                        } `}
                                      open={selectedCollapse === index}
                                    >
                                      {itm.parameters.map((param, i) => (
                                        <p className="collapsePM" key={i}>
                                          <div className="ParamterCountDisplay">
                                           
                                              <FiberManualRecordIcon />
                                            
                                            <span className="ParamterCountDisplayData">
                                              {param.parameterName === "" ? (
                                                <>No data</>
                                              ) : (
                                                <>{param.parameterName}</>
                                              )}
                                            </span>
                                          </div>
                                        </p>
                                      ))} 
                                      {itm.parameterCount !== 0 && ( 
                                        <img
                                          className="dropdownImage"
                                          src={
                                            selectedCollapse === index
                                              ? upArrow
                                              : downArrow
                                          }
                                          onClick={() => handleClick(index)}
                                        />
                                      )} 
                                    </Collapsible>
                                  </div>
                                </>
                              )
                            )}
                          </CardContent>
                        </Box>
                      </div>

                      {/* <br /> */}

                      <br />

                      <span className="About_PackageMainHeading font_FamilyA">
                        Why to Book With Us
                      </span>
                      <div className="About_PackageSubHeading font_FamilyA">
                        Yuvahealth is a healthcare service provider that offers
                        a range of medical tests, including blood tests and
                        urine tests. If you are considering booking a test with
                        Yuvahealth, here are some reasons why you should choose
                        their services:
                      </div>
                      {/* <br /> */}

                      {plans.map((item, index) => (
                        <div className="planBlock8">
                          <img
                            className="planBlock8Image"
                            src={item.Image}
                            alt="error"
                          ></img>
                          <div className="planBlockk">
                            <div className="planBlock8Text">{item.Name}</div>
                          </div>
                        </div>
                      ))}
                      <br />

                      <div className="About_PackageSubHeading font_FamilyA">
                        In conclusion, if you are looking for a reliable,
                        convenient, and affordable healthcare service provider
                        for your medical tests, Yuvahealth may be
                        <br />
                        the right choice for you. Their qualified and
                        experienced medical professionals, convenient home
                        sample
                        <br />
                        collection, advanced technology and equipment, quick
                        turnaround time, and affordable prices make them a top
                        choice in the healthcare industry.
                      </div>

                      <br />
                      <br />

                      {/* <button className="PrimeComprehensive_Button">Add</button> */}
                    </div>

                    <Footer />
                  </div>
                </div>
              ) : (
                <div>
                  <PageNotFound />
                </div>
              )}
            </>
          ) : (
            <>
              {/* { ( */}
                <div>
                  <div className="">
                    <div
                      style={{
                        backgroundImage: `url(${Package})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100%",
                        // width:"100%"
                      }}
                      className="detailTestHeadingImage"
                    >
                      <div className="PrimeComprehensiveFlex">
                        <div className="PrimeComprehensive ">
                          {packageDetails.packageName}
                        </div>
                        {/* <button className="PrimeComprehensive_Button">Add</button> */}
                        {/* {dtoList.length > 0 &&
                          dtoList.filter(
                            (x) =>
                              x.productId ===
                              packageDetails?.data?.data?.data.data.packageUuid
                          ).length > 0 ? (
                          <button className="PrimeComprehensive_ButtonAdded">
                            Added
                          </button>
                        ) : (
                          <button
                            className="PrimeComprehensive_Button"
                            onClick={() =>
                              handleAddCart(
                                packageDetails?.data?.data?.data.data
                              )
                            }
                          >
                            Add To Cart
                          </button>
                        )} */}
                      </div>
                      <div className="PrimeComprehensivePriceDisplay">
                        <div className="font_FamilyA PrimeComprehensivePrice">
                          <span
                            className="FHCSection2_Children2OldPrice"
                            style={{ color: " #A93C3C", width: "40%" }}
                          >
                            {packageDetails.packageCost === packageDetails.iscountedPackageCost
                              ? ""
                              : `₹${packageDetails.packageCost}`}
                          </span>{" "}
                          &nbsp; ₹ {packageDetails.discountedPackageCost}/-

                        </div>
                        {/* <div className="font_FamilyA PrimeComprehensiveSecondPrice">
                  ₹2000/-
                </div> */}
                      </div>

                      <div className="HomeSampleContainer">
                        <span className="HomeSampleContainerPara font_FamilyA">
                          <div className="HomeSampleContainerPara1 font_FamilyA">
                            {" "}
                            <div className="HomeSampleContainerLongParaText">
                              Home Sample Collection | Report Available within{" "}
                              {
                                packageDetails.maxReportTime
                              }{" "}
                              hrs | Unisex - 0 yrs - 99 yrs |{" "}
                              {
                                packageDetails.prerequisites
                              }
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="PackageDetailContainer">
                      <span className="About_PackageMainHeading font_FamilyA">
                        About the Package
                      </span>
                      <div className="About_PackageSubHeading font_FamilyA">
                        {packageDetails.description}
                      </div>
                      <br />
                      <br />

                      {/* <span className="About_PackageMainHeading font_FamilyA">
                Prerequisites
              </span>
              <div className="About_PackageSubHeading font_FamilyA">
                {packageDetails?.data?.data?.data.data.prerequisites}
              </div>
              <br /> */}
                      <span className="About_PackageMainHeading font_FamilyA">
                        {packageDetails.parameterCount}{" "}
                        Lab Tests in this package
                      </span>
                      <br />
                      <br />
                      <div className="About_PackageMainHeadingLabGrid1">
                        <Box>
                          <CardContent>
                            {packageDetails.map(
                              (itm, index) => (
                                <>
                                  <div
                                    className={
                                      selectedCollapse === index
                                        ? "package-card-headnew"
                                        : "package-card-head"
                                    }
                                    style={
                                      itm.parameterCount !== 0
                                        ? { cursor: "pointer" }
                                        : { cursor: "not-allowed" }
                                    }
                                    onClick={() => handleClick(index)}
                                  >
                                    <Collapsible
                                      className={
                                        selectedCollapse === index
                                          ? "Collapsiblen2"
                                          : "Collapsiblen1"
                                      }
                                      triggerDisabled={
                                        itm.parameterCount === 0 ? true : false
                                      }
                                      trigger={`${itm.attributeName} ${itm.parameterCount === 0
                                        ? ""
                                        : `-  ${itm.parameterCount} Tests       ${<img src={downArrow}></img>}     ->`
                                        } `}
                                      open={selectedCollapse === index}
                                    >
                                      {itm.parameters.map((param, i) => (
                                        <p className="collapseP" key={i}>
                                          <div className="ParamterCountDisplay">
                                            {param.parameterName === "" ? (
                                              <></>
                                            ) : (
                                              <img
                                                className="dotPackage"
                                                src={dot}
                                              ></img>
                                            )}
                                            <span className="ParamterCountDisplayData">
                                              {param.parameterName === "" ? (
                                                <>No data</>
                                              ) : (
                                                <>{param.parameterName}</>
                                              )}
                                            </span>
                                          </div>
                                        </p>
                                      ))}
                                      {itm.parameterCount !== 0 && (
                                        <img
                                          className="dropdownImage"
                                          src={
                                            selectedCollapse === index
                                              ? upArrow
                                              : downArrow 
                                          }
                                          onClick={() => handleClick(index)}
                                        />
                                      )}
                                    </Collapsible>
                                  </div>
                                </>
                              )
                            )}
                          </CardContent>
                        </Box>
                      </div>

                      <br />
                      <br />

                      <span className="About_PackageMainHeading font_FamilyA">
                        Why to Book With Us
                      </span>
                      <div className="About_PackageSubHeading font_FamilyA">
                        Yuvahealth is a healthcare service provider that offers
                        a range of medical tests, including blood tests and
                        urine tests. If you are considering booking a test with
                        Yuvahealth, here are some reasons why you should choose
                        their services:
                      </div>
                      <div className="WhyToBookWithUsContainer">
                        <div className="careerImg">
                          <img className="CareerImageMobile" src={group1}></img>
                          <div className="WhyToBookWithUshead">
                            Qualified and Experienced Medical Professionals
                          </div>
                        </div>

                        <div className="careerImg">
                          <img className="CareerImageMobile" src={group2}></img>
                          <div className="WhyToBookWithUshead">
                            Convenient Home Sample Collection
                          </div>
                        </div>
                        <div className="careerImg">
                          <img className="CareerImageMobile" src={group3}></img>
                          <div className="WhyToBookWithUshead">
                            {" "}
                            Quick Turnaround Time
                          </div>
                        </div>
                        <div className="careerImg">
                          <img className="CareerImageMobile" src={group4}></img>
                          <div className="WhyToBookWithUshead">
                            Affordable Prices
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ marginBottom: "5vw" }}
                        className="About_PackageSubHeading font_FamilyA"
                      >
                        In conclusion, if you are looking for a reliable,
                        convenient, and affordable healthcare service provider
                        for your medical tests, Yuvahealth may be
                        <br />
                        the right choice for you. Their qualified and
                        experienced medical professionals, convenient home
                        sample
                        <br />
                        collection, advanced technology and equipment, quick
                        turnaround time, and affordable prices make them a top
                        choice in the healthcare industry.
                      </div>
                    </div>
                    <Footer />
                  </div>
                </div>
              {/* ) : (
                <div>
                  <PageNotFound />
                </div>
              )} */}
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          {/* {apiCheck === "" && uuid ? (
            <></>
          ) : (
            <>
              <PageNotFound />
            </>
          )} */}
        </>
      )}
    </>
  );
}

export default PackageDetail;
