import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Copyright from "../../../assets/common/copyright.png";
import Email from "../../../assets/common/email.png";
import Instagram from "../../../assets/common/instagram.png";
import Logo from "../../../assets/whats.png";
import ALogo from "../../../assets/journeythroughladakh.png";

import Phone from "../../../assets/common/phone.png";
import "./Footer.css";
import FooterMobile from "./FooterMobile";

function Footer() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    }
  }, [window.outerWidth]);

  const handleNewsletter = () => { };

  return (
    <div>
      {isMobile ? (
        <FooterMobile />
      ) : (
        <div className="footer">
          <div className="upperFooter">
            <div className="upperChild1">
              <div className="logoImage">
              <Link  to="/">

            
                  <img className="logoFooter" src={ALogo}alt="error"/>
                </Link>
              </div>
             
             

              <div className="socialmediaContact">
                <a
                  href="https://www.instagram.com/journeythroughladakh?igsh=MW1zNDIyY3kyZHJvNA%3D%3D&utm_source=qr"
                  target="_blank"
                >
                  <img alt="Instagram" src={Instagram} />
                </a>
             
              </div>
            </div>
            <div className="upperChild2Footer">
              <div className="addressFooter">
                <p style={{ lineHeight: "1.4vw" }}>
                  Gonpa gangles, Lamdon Road  <br />
                  Ladakh <br />
                  Leh - 194101
                </p>
               
              </div>
              <div className="emailContact">
                <img alt="Email" src={Email} />
                <a
                  href="mailto: support@ladakheights.com"
                  style={{ color: "#44576A" }}
                >
                  <p>support@journeythroughladakh.com</p>{" "}
                </a>
              </div>
              <div className="phoneContact">
                <img alt="Phone" src={Phone} />
                <p>+91 9906697810 </p>
                <p>+91 6005537169 </p>

              </div>
            
            </div>
            <div className="upperChild3Footer">
              <p style={{marginLeft:"2vw"}}>Legal</p>
              <ol>
             
              <Link  to="/AboutUs">
               
                  <li>About Us</li>
                </Link>
               
              
              <Link  to="/ContactUs">
                
                
                  <li>Contact Us</li>
                </Link>
               
               
              <Link  to="/terms-and-conditions">

              
                  <li>Terms and Conditions</li>
                </Link>
               
               
               
              </ol>
            </div>
            <div className="upperChild4">
                <p>Newsletter</p>

              <div className="upperChild4Description">
                <span>
                  Signup our newsletter to get update on information, news,
                  insights and promotions.
                </span>
              </div>
              <Formik
                initialValues={{
                  Email: "",
                }}
                enableReinitialize={true}
                onSubmit={handleNewsletter}
              >
                {
                  <Form>
                    <div className="emailInput">
                      <Field
                        placeholder=" Email"
                        name="Email"
                        type="text"
                        className="newsLetterInput"
                      ></Field>
                      <ErrorMessage name="Name" component="div" />
                    </div>
                   
                  </Form>
                }
              </Formik>
              <span id="siteseal">
                <script async type="text/javascript" src="https://seal.starfieldtech.com/getSeal?sealID=ESPpgYVQ4Aie7coD4f2WEvPiNwWZhmTygcn8wBi6WR2HxkvADAL0C14ofBxp">
                </script>
              </span>
            </div>
          </div>
          <div className="downFooter">
            <div className="copyrightSign">
              <img alt="Copyright" src={Copyright} />
            </div>
            <div className="copyrightText">
              <p>Journey Through Ladakh - 2024. All right reserved.</p>
            </div>
          </div>

      <div class="fixed-bottom right-100 p-3" style={{zIndex:"6", left:"initial"}}>
        <a href={`https://wa.me/919906697810?text=Hello Journey through ladakh here, How can I help you?`} target="_blank">
          <img src={Logo} width="60" alt="aaaa"/>

        </a>

      </div>
        </div>
      )}

    </div>
  );
}

export default Footer;
