import React from "react";
// import Pill from "../../../assets/LandingPage/landingPics2.png";
import SmallServiceButton from "../../Atoms/Buttons/ServiceCard/SmallServiceButton";
import "./ourPlans.css";
import LandingBGNew2 from "../../../assets/PP2.jpeg";


function OurPlans() {
  return (
    <div className="small-card1OP"
    style={{
      backgroundImage: `url(${LandingBGNew2})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
    }}>
      <div className="small-card1-bodyOP">
        <div className="small-card1-titleOP">ACTIVITIES</div>
        {/* <div className="small-card1-paraOP">
        
     
          Explore our extensive list of
          <br />
          subscription plansand pick the one
          <br />
          that is most suited to you
        </div> */}
      </div>
      <div
        className="small-card-imgOP"
        // style={{ backgroundImage: `url(${Pill})` }}
      >
        <a href="/coming-soon">
        <button className="smallViewButton1">     View Now</button>
        </a>
        {/* <img  src={Pill} alt="Loading" /> */}
   
      </div>
    </div>
  );
}

export default OurPlans;
