import React, { useEffect, useState } from "react";
import WifiIcon from "@mui/icons-material/Wifi";
import KingBedIcon from "@mui/icons-material/KingBed";
import TvIcon from "@mui/icons-material/Tv";
import ReserveForm from "../../Pages/ReserveForm/ReserveForm";
import Footer from "../../Organisms/Footer/Footer";
import "./RoomsAndSuits.css";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";

function RoomsAndSuits() {
  const [isMobile, setIsMobile] = useState(false);
  const [add, setAdd] = useState(false);

  const roomData = [
    {
      tvSize: "32Inc TV ",
      imageSrc:
        "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-19.webp",
      price: "FROM $150/NIGHT",
      roomType: "Superior Room",
      roomDetails:
        "In our superior room, elegance meets comfort in a harmonious blend. Adorned with subtle wooden carvings, the room exudes a refined ambiance that invites relaxation. Step onto your balcony and soak in the stunning vistas of the surrounding mountains, offering a picturesque setting for unwinding after a day of exploration. Ideal for those seeking a comfortable retreat with a touch of luxury, our superior room promises a restful stay amidst the beauty of the natural world.",
    },
    {
      imageSrc:
        "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox.webp",

      roomType: "Delux Room",
      tvSize: "32Inc TV ",

      roomDetails:
        "Indulge in opulence in our deluxe room, adorned with intricate wooden carvings that epitomize elegance and sophistication. Step out onto your private balcony and behold the sweeping views of the majestic mountains, offering a serene backdrop for relaxation. Perfect for discerning travelers seeking the ultimate in comfort and luxury, our deluxe room provides a tranquil haven amidst nature's splendor.",
    },
    {
      imageSrc:
        "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-12.webp",
    
      roomType: "Suit Room",
      tvSize: "42Inc TV ",

      roomDetails:
        "Experience the epitome of luxury in our suite room, adorned with exquisite wooden carvings that exude elegance and charm. Step onto your private balcony and behold the breathtaking views of the majestic mountains, enveloped in serenity and tranquility. Ideal for those seeking a romantic escape or a peaceful retreat, our suite offers a cozy haven amidst nature's grandeur, where every detail, from the intricately carved designs to the panoramic vistas, invites you to unwind and indulge in pure bliss.",
    },
    {
      imageSrc:
        "https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-11.webp",

      roomType: "Family Room",
      tvSize: "42Inc TV ",

      roomDetails:
        " Indulge in the charm of our family suite, where comfort meets nature's grandeur. With a balcony overlooking the majestic mountains, enjoy breathtaking views that rejuvenate the soul. Perfect for creating cherished moments with your loved ones, our cozy retreat offers a serene escape amidst the beauty of the great outdoors.",
    },
  ];

  useEffect(() => {
    setIsMobile(window.outerWidth <= 600);
  }, [window.outerWidth]);

  const handleAdd = () => {
    setAdd(true);
  };

  return (
    <> 
      <ScrollToTop />
      <Helmet>
        <title>Rooms & Suites | Ladakh Heights Hotel</title>
        <meta
          name="description"
          content="Explore our luxurious rooms and suites at Ladakh Heights Hotel. Each room features modern amenities, including king-size beds, WiFi, and TV. Reserve your stay now!"
        />
        <meta name="keywords" content="ladakh hotel, ladakh heights hotel, ladakh accommodations, ladakh rooms, ladakh suites" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {add && <ReserveForm open={setAdd} />}
      <div className="roomsBodySuit">
        {isMobile ?
        <div
          className="AboutUsHeadingImage"
          style={{
            backgroundImage: `url(${"https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-13.webp"})`,
            backgroundRepeat: "no-repeat",
            // backgroundSize: "100%",
            backgroundSize: "cover", // Ensures the image covers the entire container
            backgroundPosition: "center", // Centers the image within the container
            width: "100%", // Set the desired width for your container
            height: "30vh", // Set the desired height for your container
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url("https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-13.webp")`,
          }}
        >
          <div className="AboutUsHeading">
            <p>LUXURY HOTEL EXPERIENCE</p>
            <h2 style={{ fontSize: isMobile ? "3vw" : "3vw", color: "white" }}>
              OUR ROOMS & SUITES
            </h2>
          </div>
        </div>
        :
        <div
        className="AboutUsHeadingImage"
        style={{
          backgroundImage: `url(${"https://ladakh-upload.s3.ap-south-1.amazonaws.com/RoomPic/Lightbox-13.webp"})`,
          backgroundRepeat: "no-repeat",
          // backgroundSize: "100%",
          backgroundSize: "cover", // Ensures the image covers the entire container
          backgroundPosition: "center", // Centers the image within the container
          width: "100%", // Set the desired width for your container
          height: "60vh", // Set the desired height for your container
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="AboutUsHeading">
          <p>LUXURY HOTEL EXPERIENCE</p>
          <h2 style={{ fontSize: isMobile ? "3vw" : "3vw", color: "white" }}>
            OUR ROOMS & SUITES
          </h2>
        </div>
      </div>

        }

        {roomData.map((service, index) => (
          <div key={index} className="roomAndSuitsBody">
            {isMobile ? (
              <div
                className="RoomAndSuitsImage"
                style={{
                  backgroundImage: `url(${service.imageSrc})`,
                  backgroundRepeat: "no-repeat",
                  // backgroundSize: "100%",
                  backgroundSize: "cover", // Ensures the image covers the entire container
                  backgroundPosition: "center", // Centers the image within the container
                  width: "100%", // Set the desired width for your container
                  height: "26vh", // Set the desired height for your container
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
            ) : (
              <>
                <div
                  className="RoomAndSuitsImage"
                  style={{
                    backgroundImage: `url(${service.imageSrc})`,
                    backgroundRepeat: "no-repeat",
                    // backgroundSize: "100%",
                    backgroundSize: "cover", // Ensures the image covers the entire container
                    backgroundPosition: "center", // Centers the image within the container
                    width: "100%", // Set the desired width for your container
                    height: "80vh", // Set the desired height for your container
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>
              </>
            )}

            <div
              className={
                isMobile ? "roomAndSuitsContentBox" : "roomAndSuitsContentBox1"
              }
            >
              <div style={{ padding: "2vw" }}>
                {/* <small style={{ color: "#978667" }}>{service.price}</small> */}
                <h2>{service.roomType}</h2>
                <p>{service.roomDetails}</p>
                <p className="roomAndSuitsItem">
                  <div className="roomAndSuitsItem11">
                    <KingBedIcon />
                    <p className="text">King Size Bed</p>
                  </div>
                  <div className="roomAndSuitsItem1">
                    <WifiIcon />
                    <p className="text">Wifi</p>
                  </div>
                  <div className="roomAndSuitsItem1">
                    <TvIcon />
                    <p className="text">{service.tvSize}</p>
                  </div>
                </p>
                <hr style={{ marginTop: "-1vw" }}></hr>
                <button onClick={handleAdd} className="reserveRoom">
                  Reserve Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default RoomsAndSuits;
