import React from "react";
import Blood from "../../../assets/LandingPage/landingPics3.png";
import SmallServiceButton from "../../Atoms/Buttons/ServiceCard/SmallServiceButtonDiagnostic";
import "./diagnosticService.css";
import LandingBGNew2 from "../../../assets/HOTELS.jpeg";

function Diagnostics() {
  return (
    <div className="small-card1DS"  style={{
      backgroundImage: `url(${LandingBGNew2})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
    }} >
      <div className="small-card1-bodyDS">
        <div className="small-card1-titleDS">HOTELS</div>
        {/* <div className="small-card1-paraDS">
          <span >
           
          </span>
          Comprehensive Diagnostic Tests at <br />the tips of your fingers
        </div> */}
      </div>
      <div
        className="small-card-imgDS"
        // style={{ backgroundImage: `url(${Blood})` }}
      >
        <a href="/coming-soon">
          <SmallServiceButton text={"Check Now"} />
        </a>
        {/* <img  src={Blood} alt="Loading" /> */}
      </div>
    </div>
  );
}

export default Diagnostics;
