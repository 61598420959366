import React, { useEffect, useState } from 'react'
import AboutUsHeading from '../../../assets/bike2.jpeg'
import { Helmet } from 'react-helmet'
import dots from '../../../assets/PlanPage/dots.png'
import tick from '../../../assets/tick.png'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/system'
import './ViewDetailsPlanServices.css'
import './PackageDetail.css'
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DataGrid } from '@mui/x-data-grid'
import Footer from '../../Organisms/Footer/Footer'
import Testimonal from '../../Pages/Testimonal/Testimonal'
import './PackageMainPage.css'
import { useLocation } from 'react-router-dom'
import ScrollToTop from '../../Molecules/ScrollToTop/ScrollToTop'
import LandingPageGalleryCarousel from '../LandingPage/LandingPageGalleryCarousel'
import PackageDetail from './PackageDetail'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { CardContent } from '@mui/material'
import Collapsible from 'react-collapsible'
import { useParams } from 'react-router-dom'
import dot from '../../../assets/Health_Checkups_Assests/dot.png'
import downArrow from '../../../assets/Package/DownArrow.png'
import group1 from '../../../assets/Package/Group1.png'
import group2 from '../../../assets/Package/Group2.png'
import group3 from '../../../assets/Package/Group3.png'
import group4 from '../../../assets/Package/Group4.png'
import Package from '../../../assets/Package/package_bg.png'
import upArrow from '../../../assets/Package/upArrow.png'

// import { Gallery } from "react-grid-gallery";

function PackageMainPage () {
  const { id } = useParams()
  const location = useLocation()
  console.log('datas', location.state[id])
  var allData = location.state[id]
  console.log('data', allData.car)
  // const [package,setPackage]=useState()
  const [isMobile, setIsMobile] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const [value, setValue] = useState()

  const [selectedCollapse, setSelectedCollapse] = useState(null)

  const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/

  const loginSchema = Yup.object().shape({
    bikeType: Yup.string().required('Required Field'),
    person: Yup.string().required('Required Field'),
    date: Yup.string().required('Required Field'),
    month: Yup.string().required('Required Field'),
    email: Yup.string().required('Required Field'),

    name: Yup.string()
      .required('Required Field')
      .matches(/^(?![.])[A-Za-z .]+$/, 'Insert only normal character'),
    number: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Required')
  })

  const handleCheckEmail = event => {
    // dispatch(checkEmailAgentRequest(event.target.value));
  }
  const handleSubmit = e => {
    console.log('inside', e)
    if (true) {
      const updatedFormData = {
        ...e, // Spread the existing properties of the event target
        value: value // Add a new field with its value
      }
      const whatsappMessage = `Trip: ${allData.packageName}%0AName: ${e.name}%0AEmail: ${e.email}%0ANumber: ${e.number}%0AMonth: ${e.month}%0ADate: ${e.date}%0ATransportation Type: ${e.bikeType}%0ANumber of person: ${e.person}`
      const whatsappLink = `https://wa.me/919906697810/?text=${whatsappMessage}`
      window.open(whatsappLink, '_blank')

      // emailjs.send('service_ulw10xi', 'template_s987n48', updatedFormData).then(
      //   result => {
      //     Swal.fire({
      //       title: 'Succcessful!',
      //       text: 'We will get back to you soon!',
      //       icon: 'success'
      //     })
      //     // handleClose();
      //   },
      //   error => {
      //     console.log(error.text)
      //     console.log('error')
      //   }
      // )
    } else {
      Swal.fire({
        title: 'select!',
        text: 'Please select a date',
        icon: 'info'
      })
    }
  }

  const handleCheckNumber = event => {
    if (event.target.value.length === 10) {
    }
  }

  function preventnumberinput (e) {
    var keycode = e.keycode ? e.keycode : e.which
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault()
    }
  }
  const toggle = i => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }

  const compliment = [
    'Biker Jackets for Rider and Pillion',
    'Knee Guards for Rider and Pillion',
    'Standard size helmet',
    'Participate Certificate'
  ]

  const confirmPolicy = [
    'Payments can be done in 3 parts.',
    'After processing the booking amount you will get an email confirmation.',
    'Once you paid the 100% amount for your booking, you will get an invoice containing all the information about the trip.'
  ]

  const paymentPolicy = [
    'Booking amount 3,000/- (Non Refundable and Non Adjustable)',
    '40 Days before the trip departure 60% of the total cost.',
    '15 Days before the trip departure 100% of the total cost.'
  ]
  const cancellPolicy = [
    'Cancellations are made before 35 to 15 days from the date of travel then 50% of the total trip will be charged.',
    'Cancellations are made between 0 to 10  days from the date of travel then 100% of the total trip will be charged. '
  ]

  const refundPolicy = [
    'Refund amount will be processed within 2 weeks of business days.',
    'All applicable refunds will be done in the traveller’s Bank Transfer only'
  ]

  const columnsPrivate = [
    {
      field: 'privateTour',
      headerClassName: 'super-app-theme--header',
      headerName: ' Private Tour ',
      flex: 3,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: 'Nunito'
    },

    {
      field: 'pricePerAdult',
      headerClassName: 'super-app-theme--header',
      headerName: 'Price per adult',
      flex: 3,
      alignItems: 'Right',
      disableColumnMenu: true,
      sortable: false,
      fontFamily: 'Nunito'
    }
  ]
  const columns = [
    {
      field: 'groupTour',
      headerClassName: 'super-app-theme--header',
      headerName: ' Group Tour ',
      flex: 3,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: 'Nunito'
    },

    {
      field: 'pricePerAdult',
      headerClassName: 'super-app-theme--header',
      headerName: 'Price per adult',
      flex: 3,
      alignItems: 'Right',
      disableColumnMenu: true,
      sortable: false,
      fontFamily: 'Nunito'
    }
  ]

  const columnsBike = [
    {
      field: 'bike',
      headerClassName: 'super-app-theme--header',
      headerName: ' Bike Tour ',
      flex: 3,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: 'Nunito'
    },

    {
      field: 'cost',
      headerClassName: 'super-app-theme--header',
      headerName: 'cost per adult',
      flex: 3,
      alignItems: 'Right',
      disableColumnMenu: true,
      sortable: false,
      fontFamily: 'Nunito'
    }
  ]
  const dataBike = [
    {
      id: 1,
      bike: 'Own Bike Own Fuel',
      cost: `${allData.OwnBike}  per adult`
    },
    {
      id: 2,
      bike: 'Bullet 500 cc dual  ',
      cost: `${allData.BulletD}  per adult`
    },
    {
      id: 3,
      bike: 'Himalayan 411 cc dual  ',
      cost: `${allData.HimalayanD}  per adult`
    },
    {
      id: 4,
      bike: 'Bullet 500 cc Solo  ',
      cost: `${allData.BulletS}  per adult`
    },
    {
      id: 5,
      bike: 'Himalayan 411 cc Solo  ',
      cost: `${allData.HimalayanS}  per adult`
    }
  ]

  const data = [
    {
      id: 1,
      groupTour: 'Tempo Traveller 12 Pax ',
      pricePerAdult: `${allData.tempo12}  per adult`
    },
    {
      id: 2,
      groupTour: 'Tempo Traveller 8 Pax ',
      pricePerAdult: `${allData.tempo8}  per adult`
    }
  ]
  const dataPrivate = [
    {
      id: 1,
      privateTour: 'Mahindra Xylo 06 pax',
      pricePerAdult: `${allData.suv6}  per adult`
    },
    {
      id: 2,
      privateTour: 'Mahindra Xylo 04 pax',
      pricePerAdult: `${allData.suv4}  per adult`
    },
    {
      id: 3,
      privateTour: 'Mahindra Xylo 02 pax',
      pricePerAdult: `${allData.suv2}  per adult`
    }
  ]
  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window.outerWidth])
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>About Ladakh Travel | Luxury Boutique Retreat in Ladakh</title>
        <meta
          name='description'
          content='Discover the allure of Ladakh Heights, a luxury boutique retreat located just 4kms from the bustling market. Enjoy captivating panoramas, spacious rooms, and traditional aesthetics amidst breathtaking vistas. Book your stay now!'
        />
        <meta
          name='keywords'
          content='ladakh heights, ladakh hotel, ladakh boutique hotel, ladakh luxury hotel, ladakh retreat, ladakh accommodations'
        />
        <meta name='robots' content='index, follow' />
      </Helmet>

      {isMobile ? (
        <>
          <div className='AboutUsBody'>
            <div
              style={{
                backgroundImage: `url(${AboutUsHeading})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: "100%",
                backgroundSize: 'cover', // Ensures the image covers the entire container
                backgroundPosition: 'center', // Centers the image within the container
                width: '100%', // Set the desired width for your container
                height: '30vh', // Set the desired height for your container
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className='AboutUsHeadingImage'
            >
              {/* <div className="img-mask"></div> */}

              <div className='AboutUsHeading'>
                {/* <p>LUXURY HOTEL EXPERIENCE</p> */}
                <h2 style={{ fontSize: '3vw', color: 'black' }}>
                  Package Details
                </h2>
              </div>
            </div>

            <div>
              <div className='packageMainPageGridBodyMbl'>
                <div className='packageMainPageGrid1Mbl'>
                  <h1 className='mainHeadingPackagenameMbl'>
                    {' '}
                    {allData.packageName}
                  </h1>
                  <h4>{allData.car ? <>Suv Tour</> : <>Bike Tour</>}</h4>

                  <h2>
                    INR- {allData.car ? allData.tempo12 : allData.OwnBike}
                  </h2>
                  <p>per person</p>

                  {allData.car ? (
                    <div
                      style={{
                        // paddingLeft: "14vw",
                        // paddingRight: "14vw",
                        paddingBottom: '10vw'
                      }}
                    >
                      <Box
                        sx={{
                          height: 600,
                          display: 'inline-block',
                          width: '100%',
                          marginTop: '2%'
                        }}
                      >
                        {data && (
                          <DataGrid
                            sx={{
                              color: '#1D2334',
                              '& .super-app-theme--header': {
                                backgroundColor: '#52608E',
                                color: '#F2EFEA',
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                '& > .MuiDataGrid-columnSeparator': {
                                  visibility: 'hidden'
                                }
                              },
                              fontFamily: 'Nunito',
                              '&:hover:not(.Mui-disabled)': {
                                cursor: 'pointer'
                              },
                              '.MuiTablePagination-toolbar': {
                                alignItems: 'baseline',
                                marginTop: '5%'
                              }
                            }}
                            rows={data}
                            columns={columns}
                            components={{
                              Footer: 'none'
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          height: 600,
                          display: 'inline-block',
                          width: '100%',
                          marginTop: '2%'
                        }}
                      >
                        {data && (
                          <DataGrid
                            sx={{
                              color: '#1D2334',
                              '& .super-app-theme--header': {
                                backgroundColor: '#52608E',
                                color: '#F2EFEA',
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                '& > .MuiDataGrid-columnSeparator': {
                                  visibility: 'hidden'
                                }
                              },
                              fontFamily: 'Nunito',
                              '&:hover:not(.Mui-disabled)': {
                                cursor: 'pointer'
                              },
                              '.MuiTablePagination-toolbar': {
                                alignItems: 'baseline',
                                marginTop: '5%'
                              }
                            }}
                            rows={dataPrivate}
                            columns={columnsPrivate}
                            components={{
                              Footer: 'none'
                            }}
                          />
                        )}
                      </Box>
                    </div>
                  ) : (
                    <>
                      {' '}
                      <div
                        style={{
                          // paddingLeft: "14vw",
                          // paddingRight: "14vw",
                          paddingBottom: '10vw'
                        }}
                      >
                        <Box
                          sx={{
                            height: 600,
                            display: 'inline-block',
                            width: '100%',
                            marginTop: '2%'
                          }}
                        >
                          {data && (
                            <DataGrid
                              sx={{
                                color: '#1D2334',
                                '& .super-app-theme--header': {
                                  backgroundColor: '#52608E',
                                  color: '#F2EFEA',
                                  fontFamily: 'Nunito',
                                  fontWeight: 600,
                                  '& > .MuiDataGrid-columnSeparator': {
                                    visibility: 'hidden'
                                  }
                                },
                                fontFamily: 'Nunito',
                                '&:hover:not(.Mui-disabled)': {
                                  cursor: 'pointer'
                                },
                                '.MuiTablePagination-toolbar': {
                                  alignItems: 'baseline',
                                  marginTop: '5%'
                                }
                              }}
                              rows={dataBike}
                              columns={columnsBike}
                              components={{
                                Footer: 'none'
                              }}
                            />
                          )}
                        </Box>
                        {/* <Box
                    sx={{
                      height: 600,
                      display: 'inline-block',
                      width: '100%',
                      marginTop: '2%'
                    }}
                  >
                    {data && (
                      <DataGrid
                        sx={{
                          color: '#1D2334',
                          '& .super-app-theme--header': {
                            backgroundColor: '#52608E',
                            color: '#F2EFEA',
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            '& > .MuiDataGrid-columnSeparator': {
                              visibility: 'hidden'
                            }
                          },
                          fontFamily: 'Nunito',
                          '&:hover:not(.Mui-disabled)': {
                            cursor: 'pointer'
                          },
                          '.MuiTablePagination-toolbar': {
                            alignItems: 'baseline',
                            marginTop: '5%'
                          }
                        }}
                        rows={dataPrivate}
                        columns={columnsPrivate}
                        components={{
                          Footer: 'none'
                        }}
                      />
                    )}
                  </Box> */}
                      </div>
                    </>
                  )}
                  {/* <PackageDetail/> */}
                  <h1 className='MainHeading'>Highlight Places and Passes</h1>
                  <h6 className='subHeading'>Places Cover</h6>
                  <p className='text'>{allData.placesCover} </p>
                  <h6 className='subHeading'>Passes Cover</h6>
                  <p className='text'>
                    {allData.passesCover}

                    {/* Khardung La (17,982 ft) – Chang La (17,688 ft){' '} */}
                  </p>
                  <h6 className='subHeading'>Overview</h6>
                  <p className='text'>
                    {allData.overview.map((item, i) => (
                      <>
                        {item}
                        <br />
                      </>
                    ))}
                    {/* Area – Ladakh <br />
                    Kilometer – 600 approx <br />
                    Duration – 04 Nights – 05 Days <br />
                    Max Altitude – 17,982 ft <br />
                    Number of Passes – 02 <br />
                    Number of Lakes – 01 <br />
                    Trip start – Leh Airport <br />
                    Trip End – Leh Airport <br />
                    Best time – May to September{' '} */}
                  </p>

                  <h6 className='subHeading'>Accommodation Details</h6>
                  <p className='text'>
                    {allData.accommodation.map((item, i) => (
                      <>
                        {item}
                        {i !== 0 ? (
                          <>
                            {' '}
                            <br />
                          </>
                        ) : (
                          <>
                            {' '}
                            <br /> <br />
                          </>
                        )}
                      </>
                    ))}
                  </p>
                  <h3 className='MainHeadingItineary'>Itineary</h3>

                  {/* <div className="FrequentlyAskedQuestionContainer"> */}
                  <div className='FrequentlyAskedQuestionGrid'>
                    <div className='FrequentlyAskedQuestionDataContentM'>
                      {allData.itineary.map((item, i) => (
                        <div className='FrequentlyAskedQuestionGriditemM'>
                          <div
                            className='FrequentlyAskedQuestionitemtitleM'
                            onClick={() => toggle(i)}
                          >
                            <div className='FrequentlyAskedQuestionQuestion'>
                              {item.question}
                            </div>
                            <span>
                              {selected === i ? (
                                <div className='PlusSymbol'>
                                  <ExpandLessIcon />
                                </div>
                              ) : (
                                <div className='PlusSymbol'>
                                  <ExpandMoreIcon />
                                </div>
                              )}
                            </span>
                          </div>
                          <div
                            className={
                              selected === i
                                ? 'FrequentlyAskedQuestionitemcontentM show'
                                : 'FrequentlyAskedQuestionitemcontentM'
                            }
                          >
                            <div className='FrequentlyAskedQuestionANswerM'>
                              {/* {item.answer} */}
                              {item.answer.map((item, index) => (
                                <div className='PlanTermsAndConditionsContainerList'>
                                  <span>
                                    <img
                                      alt=''
                                      className='mblDots1'
                                      src={dots}
                                    ></img>
                                  </span>
                                  <div className='PlanTermsAndConditionsSubHeadingMbl'>
                                    {item}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* </div> */}
                    </div>
                  </div>
                  <hr />
                  {!allData.car && (
                    <>
                      <div className='includeGridBodyMbl'>
                        <div className='priceIncludeMbl'>
                          Complimentary for Trip
                        </div>
                        <div>
                          {compliment.map((item, index) => (
                            <div className='PlanTermsAndConditionsContainerList'>
                              <span>
                                <img
                                  alt=''
                                  className='mblDots1'
                                  src={tick}
                                ></img>
                              </span>
                              <div className='PlanTermsAndConditionsSubHeadingMbl'>
                                {item}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='includeGridBodyMbl'>
                    <div className='priceIncludeMbl'>Price Include</div>
                    <div>
                      {allData.Include.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={tick}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeadingMbl'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr />
                  <div className='includeGridBodyMbl'>
                    <div className='priceIncludeMbl'>Price Exclude</div>
                    <div>
                      {allData.exclude.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <CloseIcon />
                            {/* <img alt='' className='mblDots1' src={dots}></img> */}
                          </span>
                          <div className='PlanTermsAndConditionsSubHeadingMbl'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr />

                  <div className='PlanTermsAndConditionsContainer'>
                    <h6 className='subHeading'>Confirmation Policy </h6>

                    <div className='PlanServicesDetailsListingWeb'>
                      {confirmPolicy.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={dots}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeadingMbl'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                    <h6 className='subHeading'>Payment Terms Policy </h6>

                    <div className='PlanServicesDetailsListingWeb'>
                      {paymentPolicy.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={dots}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeadingMbl'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                    <h6 className='subHeading'>Cancellation Policy </h6>

                    <div className='PlanServicesDetailsListingWeb'>
                      {cancellPolicy.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={dots}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeadingMbl'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                    <h6 className='subHeading'>Refund Policy </h6>

                    <div className='PlanServicesDetailsListingWeb'>
                      {refundPolicy.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={dots}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeadingMbl'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='whyChooseUsMbl'>
                    <h6> Fix Departure Dates</h6>
                    <p style={{ marginTop: '1em' }}> May - 3, 10, 17, 24</p>
                    <hr />
                    <p> June - 01, 07, 14, 21, 28</p>
                    <hr />
                    <p>July - 05, 12, 20, 26</p>
                    <hr />
                    <p>August - 2, 10, 16, 23, 30 </p>
                    <hr />

                    <p>September - 6, 13, 20, 25 </p>
                    <hr />

                    <p>October - 4 </p>
                  </div>
                  <div className='bookingformBodyMbl'>
                    <h6 className='bookPackagecardHeadingMbl'>
                      {' '}
                      Holiday Package
                    </h6>
                    {/* <p> from 16000rs</p> */}
                    <h4>Booking Form</h4>
                    <div>
                      <Formik
                        initialValues={{
                          email: '',
                          date: '',
                          name: '',
                          number: '',
                          month: '',
                          bikeType: '',
                          person: ''
                        }}
                        enableReinitialize={true}
                        validationSchema={loginSchema}
                        onSubmit={handleSubmit}
                      >
                        {
                          <Form>
                            <div className='inputFieldsContainer'>
                              {/* <div
                                className='dateTime'
                                style={{ width: '100%', marginBottom: '3vw' }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DatePicker
                                    slotProps={{
                                      textField: { fullWidth: true }
                                    }}
                                    renderInput={props => (
                                      <TextField {...props} />
                                    )}
                                    label='Date'
                                    minDate={new Date()}
                                    value={value}
                                    inputFormat='dd/MM/yyyy' // Format for date only
                                    onChange={newValue => {
                                      setValue(newValue)
                                    }}
                                    openTo='day' // To show the calendar for selecting dates
                                  />
                                </LocalizationProvider>
                              </div> */}
                              <label>
                                <Field
                                  as='select'
                                  name='month'
                                  placeholder='Select month'
                                  className='inputFieldBooking'
                                >
                                  <option value='' disabled selected>
                                    Select Month
                                  </option>
                                  <option>May</option>
                                  <option>June</option>
                                  <option>July</option>
                                  <option>August</option>
                                  <option>September</option>
                                  <option>October</option>
                                </Field>
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='month'
                                  component='div'
                                />
                              </label>
                              <label>
                                <Field
                                  type='number'
                                  name='date'
                                  placeholder='Date (choose from the given fix departure date)'
                                  className='inputFieldBooking'
                                  // onBlur={(e) => {
                                  //   handleCheckNumber(e);
                                  // }}
                                />
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='date'
                                  component='div'
                                />
                              </label>
                              <label>
                                <Field
                                  as='select'
                                  name='bikeType'
                                  placeholder='Select City'
                                  className='inputFieldBooking'
                                >
                                  <option value='' disabled selected>
                                    Bike Type
                                  </option>
                                  <option>OwnBike</option>
                                  <option>Bullet 500cc Dual</option>
                                  <option>Bullet 500cc solo</option>
                                  <option>Himalayan 411cc Dual</option>
                                  <option>Himalayan 411cc Solo</option>
                                </Field>
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='bikeType'
                                  component='div'
                                />
                              </label>
                              <label>
                                <Field
                                  as='select'
                                  name='person'
                                  placeholder='No fo person'
                                  className='inputFieldBooking'
                                >
                                  <option value='' disabled selected>
                                    No Of person
                                  </option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                  <option>7</option>
                                  <option>8</option>
                                  <option>9</option>
                                  <option>10</option>
                                  <option>11</option>
                                  <option>12</option>
                                </Field>
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='person'
                                  component='div'
                                />
                              </label>

                              <label>
                                <Field
                                  type=''
                                  name='name'
                                  placeholder='Name'
                                  className='inputFieldBooking'
                                  onKeyDown={event => preventnumberinput(event)}
                                />
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='name'
                                  component='div'
                                />
                              </label>
                              <label>
                                <Field
                                  type='number'
                                  name='number'
                                  placeholder='Phone No'
                                  className='inputFieldBooking'
                                  // onBlur={(e) => {
                                  //   handleCheckNumber(e);
                                  // }}
                                />
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='number'
                                  component='div'
                                />
                              </label>
                              <label>
                                <Field
                                  type='email'
                                  name='email'
                                  onBlur={e => {
                                    handleCheckEmail(e)
                                  }}
                                  placeholder='Contact Email '
                                  className='inputFieldBooking'
                                />
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='email'
                                  component='div'
                                />
                              </label>
                              <div className='bookingButtonBody'>
                                <button
                                  type='submit'
                                  // onClick={handleClose}
                                  className={
                                    isMobile ? 'modalButton' : 'modalButton2'
                                  }
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </Form>
                        }
                      </Formik>
                    </div>
                  </div>

                  <div className='whyChooseUsMbl'>
                    <h6> Why Book With Us?</h6>
                    <p style={{ marginTop: '1em' }}>
                      {' '}
                      Local agent no middle man
                    </p>
                    <hr />
                    <p> Customer care available 24/7</p>
                    <hr />
                    <p>Hand-picked Tours & Activities </p>
                    <hr />
                    <p>Free Complimentary Riding Gears </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <Footer />
        </>
      ) : (
        <>
          <div className='AboutUsBody'>
            <div
              style={{
                backgroundImage: `url(${AboutUsHeading})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: "100%",
                backgroundSize: 'cover', // Ensures the image covers the entire container
                backgroundPosition: 'center', // Centers the image within the container
                width: '100%', // Set the desired width for your container
                height: '60vh', // Set the desired height for your container
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className='AboutUsHeadingImage'
            >
              {/* <div className="img-mask"></div> */}

              <div className='AboutUsHeading'>
                {/* <p>LUXURY HOTEL EXPERIENCE</p> */}
                <h2 style={{ fontSize: '3vw', color: 'black' }}>
                  Package Details
                </h2>
              </div>
            </div>

            <div>
              <div className='packageMainPageGridBody'>
                <div className='packageMainPageGrid1'>
                  <h1 className='mainHeadingPackagename'>
                    {' '}
                    {allData.packageName}
                  </h1>

                  <h2>INR- {allData.tempo12}</h2>
                  <p>per peron</p>

                  {allData.car ? (
                    <div
                      style={{
                        // paddingLeft: "14vw",
                        // paddingRight: "14vw",
                        paddingBottom: '4vw'
                      }}
                    >
                      <Box
                        sx={{
                          height: 600,
                          display: 'inline-block',
                          width: '100%',
                          marginTop: '2%'
                        }}
                      >
                        {data && (
                          <DataGrid
                            sx={{
                              color: '#1D2334',
                              '& .super-app-theme--header': {
                                backgroundColor: '#52608E',
                                color: '#F2EFEA',
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                '& > .MuiDataGrid-columnSeparator': {
                                  visibility: 'hidden'
                                }
                              },
                              fontFamily: 'Nunito',
                              '&:hover:not(.Mui-disabled)': {
                                cursor: 'pointer'
                              },
                              '.MuiTablePagination-toolbar': {
                                alignItems: 'baseline',
                                marginTop: '5%'
                              }
                            }}
                            rows={data}
                            columns={columns}
                            components={{
                              Footer: 'none'
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          height: 600,
                          display: 'inline-block',
                          width: '100%',
                          marginTop: '2%'
                        }}
                      >
                        {data && (
                          <DataGrid
                            sx={{
                              color: '#1D2334',
                              '& .super-app-theme--header': {
                                backgroundColor: '#52608E',
                                color: '#F2EFEA',
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                '& > .MuiDataGrid-columnSeparator': {
                                  visibility: 'hidden'
                                }
                              },
                              fontFamily: 'Nunito',
                              '&:hover:not(.Mui-disabled)': {
                                cursor: 'pointer'
                              },
                              '.MuiTablePagination-toolbar': {
                                alignItems: 'baseline',
                                marginTop: '5%'
                              }
                            }}
                            rows={dataPrivate}
                            columns={columnsPrivate}
                            components={{
                              Footer: 'none'
                            }}
                          />
                        )}
                      </Box>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          // paddingLeft: "14vw",
                          // paddingRight: "14vw",
                          paddingBottom: '4vw'
                        }}
                      >
                        <Box
                          sx={{
                            height: 600,
                            display: 'inline-block',
                            width: '100%',
                            marginTop: '2%'
                          }}
                        >
                          {data && (
                            <DataGrid
                              sx={{
                                color: '#1D2334',
                                '& .super-app-theme--header': {
                                  backgroundColor: '#52608E',
                                  color: '#F2EFEA',
                                  fontFamily: 'Nunito',
                                  fontWeight: 600,
                                  '& > .MuiDataGrid-columnSeparator': {
                                    visibility: 'hidden'
                                  }
                                },
                                fontFamily: 'Nunito',
                                '&:hover:not(.Mui-disabled)': {
                                  cursor: 'pointer'
                                },
                                '.MuiTablePagination-toolbar': {
                                  alignItems: 'baseline',
                                  marginTop: '5%'
                                }
                              }}
                              rows={dataBike}
                              columns={columnsBike}
                              components={{
                                Footer: 'none'
                              }}
                            />
                          )}
                        </Box>
                      </div>
                    </>
                  )}
                  {/* <PackageDetail/> */}
                  <h1 className='MainHeading'>Highlight Places and Passes</h1>
                  <h6 className='subHeading'>Places Cover</h6>
                  <p className='text'>{allData.placesCover} </p>
                  <h6 className='subHeading'>Passes Cover</h6>
                  <p className='text'>
                    {allData.passesCover}

                    {/* Khardung La (17,982 ft) – Chang La (17,688 ft){' '} */}
                  </p>
                  <h6 className='subHeading'>Overview</h6>
                  <p className='text'>
                    {allData.overview.map((item, i) => (
                      <>
                        {item}
                        <br />
                      </>
                    ))}
                    {/* Area – Ladakh <br />
                    Kilometer – 600 approx <br />
                    Duration – 04 Nights – 05 Days <br />
                    Max Altitude – 17,982 ft <br />
                    Number of Passes – 02 <br />
                    Number of Lakes – 01 <br />
                    Trip start – Leh Airport <br />
                    Trip End – Leh Airport <br />
                    Best time – May to September{' '} */}
                  </p>

                  <h6 className='subHeading'>Accommodation Details</h6>
                  <p className='text'>
                    {allData.accommodation.map((item, i) => (
                      <>
                        {item}
                        {i !== 0 ? (
                          <>
                            {' '}
                            <br />
                          </>
                        ) : (
                          <>
                            {' '}
                            <br /> <br />
                          </>
                        )}
                      </>
                    ))}
                  </p>
                  <h3 className='MainHeadingItineary'>Itineary</h3>

                  {/* <div className="FrequentlyAskedQuestionContainer"> */}
                  <div className='FrequentlyAskedQuestionGrid'>
                    <div className='FrequentlyAskedQuestionDataContent1'>
                      {allData.itineary.map((item, i) => (
                        <div className='FrequentlyAskedQuestionGriditem'>
                          <div
                            className='FrequentlyAskedQuestionitemtitle'
                            onClick={() => toggle(i)}
                          >
                            <div className='FrequentlyAskedQuestionQuestionW'>
                              {item.question}
                            </div>
                            <span>
                              {selected === i ? (
                                <div className='PlusSymbol'>
                                  <ExpandLessIcon />
                                </div>
                              ) : (
                                <div className='PlusSymbol'>
                                  <ExpandMoreIcon />
                                </div>
                              )}
                            </span>
                          </div>
                          <div
                            className={
                              selected === i
                                ? 'FrequentlyAskedQuestionitemcontent show'
                                : 'FrequentlyAskedQuestionitemcontent'
                            }
                          >
                            <div className='FrequentlyAskedQuestionAnswerW'>
                              {/* {item.answer} */}
                              {item.answer.map((item, index) => (
                                <div className='PlanTermsAndConditionsContainerList'>
                                  <span>
                                    <img
                                      alt=''
                                      className='mblDots1'
                                      src={dots}
                                    ></img>
                                  </span>
                                  <div className='PlanTermsAndConditionsSubHeading'>
                                    {item}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* </div> */}
                    </div>
                  </div>
                  <hr />
                  {allData?.car && (
                    <>
                      <div className='includeGridBody'>
                        <div>Complimentary for Trip</div>

                        <div>
                          {compliment.map((item, index) => (
                            <div className='PlanTermsAndConditionsContainerList'>
                              <span>
                                <img
                                  alt=''
                                  className='mblDots1'
                                  src={tick}
                                ></img>
                              </span>
                              <div className='PlanTermsAndConditionsSubHeading'>
                                {item}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr />
                    </>
                  )}

                  <div className='includeGridBody'>
                    <div>Price Include</div>
                    <div>
                      {allData.Include.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={tick}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeading'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr />
                  <div className='includeGridBody'>
                    <div>Price Exclude</div>
                    <div>
                      {allData.exclude.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <CloseIcon />
                            {/* <img alt='' className='mblDots1' src={dots}></img> */}
                          </span>
                          <div className='PlanTermsAndConditionsSubHeading'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr />

                  <div className='PlanTermsAndConditionsContainer'>
                    <h6 className='subHeading'>Confirmation Policy </h6>

                    <div className='PlanServicesDetailsListingWeb'>
                      {confirmPolicy.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={dots}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeading'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                    <h6 className='subHeading'>Payment Terms Policy </h6>

                    <div className='PlanServicesDetailsListingWeb'>
                      {paymentPolicy.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={dots}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeading'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                    <h6 className='subHeading'>Cancellation Policy </h6>

                    <div className='PlanServicesDetailsListingWeb'>
                      {cancellPolicy.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={dots}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeading'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                    <h6 className='subHeading'>Refund Policy </h6>

                    <div className='PlanServicesDetailsListingWeb'>
                      {refundPolicy.map((item, index) => (
                        <div className='PlanTermsAndConditionsContainerList'>
                          <span>
                            <img alt='' className='mblDots1' src={dots}></img>
                          </span>
                          <div className='PlanTermsAndConditionsSubHeading'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='bookingformBody'>
                    <h6> Holiday Package</h6>
                    {/* <p> from 16000rs</p> */}
                    <h4>Booking Form</h4>
                    <div>
                      <Formik
                        initialValues={{
                          email: '',
                          date: '',
                          name: '',
                          number: '',
                          month: '',
                          bikeType: '',
                          person: ''
                        }}
                        enableReinitialize={true}
                        validationSchema={loginSchema}
                        onSubmit={handleSubmit}
                      >
                        {
                          <Form>
                            <div className='inputFieldsContainer'>
                              {/* <div
                                className='dateTime'
                                style={{ width: '100%', marginBottom: '3vw' }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DatePicker
                                    slotProps={{
                                      textField: { fullWidth: true }
                                    }}
                                    renderInput={props => (
                                      <TextField {...props} />
                                    )}
                                    label='Date'
                                    minDate={new Date()}
                                    value={value}
                                    inputFormat='dd/MM/yyyy' // Format for date only
                                    onChange={newValue => {
                                      setValue(newValue)
                                    }}
                                    openTo='day' // To show the calendar for selecting dates
                                  />
                                </LocalizationProvider>
                              </div> */}

                              <label>
                                <Field
                                  as='select'
                                  name='month'
                                  placeholder='Select month'
                                  className='inputFieldBooking'
                                >
                                  <option value='' disabled selected>
                                    Select Month
                                  </option>
                                  <option>May</option>
                                  <option>June</option>
                                  <option>July</option>
                                  <option>August</option>
                                  <option>September</option>
                                  <option>October</option>
                                </Field>
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='month'
                                  component='div'
                                />
                              </label>
                              <label>
                                <Field
                                  type='number'
                                  name='date'
                                  placeholder='Date (choose from the given fix departure date)'
                                  className='inputFieldBooking'
                                  onBlur={e => {
                                    handleCheckNumber(e)
                                  }}
                                />
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='date'
                                  component='div'
                                />
                              </label>
                              {allData.car ? (
                                <label>
                                  <Field
                                    as='select'
                                    name='bikeType'
                                    placeholder='Select City'
                                    className='inputFieldBooking'
                                  >
                                    <option value='' disabled selected>
                                      Transportation Type
                                    </option>
                                    <option>Tempo Traveller (Force)</option>
                                    <option>SUV Xylo</option>
                                  </Field>
                                  <ErrorMessage
                                    className='errorMessageModalValidation'
                                    name='bikeType'
                                    component='div'
                                  />
                                </label>
                              ) : (
                                <label>
                                  <Field
                                    as='select'
                                    name='bikeType'
                                    placeholder='Select City'
                                    className='inputFieldBooking'
                                  >
                                    <option value='' disabled selected>
                                      Bike Type
                                    </option>
                                    <option>OwnBike</option>
                                    <option>Bullet 500cc Dual</option>
                                    <option>Bullet 500cc solo</option>
                                    <option>Himalayan 411cc Dual</option>
                                    <option>Himalayan 411cc Solo</option>
                                  </Field>
                                  <ErrorMessage
                                    className='errorMessageModalValidation'
                                    name='bikeType'
                                    component='div'
                                  />
                                </label>
                              )}
                              <label>
                                <Field
                                  as='select'
                                  name='person'
                                  placeholder='No Of Person'
                                  className='inputFieldBooking'
                                >
                                  <option value='' disabled selected>
                                    No Of person
                                  </option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                  <option>7</option>
                                  <option>8</option>
                                  <option>9</option>
                                  <option>10</option>
                                  <option>11</option>
                                  <option>12</option>
                                </Field>
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='person'
                                  component='div'
                                />
                              </label>

                              <label>
                                <Field
                                  type=''
                                  name='name'
                                  placeholder='Name'
                                  className='inputFieldBooking'
                                  onKeyDown={event => preventnumberinput(event)}
                                />
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='name'
                                  component='div'
                                />
                              </label>
                              <label>
                                <Field
                                  type='number'
                                  name='number'
                                  placeholder='Phone No'
                                  className='inputFieldBooking'
                                  // onBlur={(e) => {
                                  //   handleCheckNumber(e);
                                  // }}
                                />
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='number'
                                  component='div'
                                />
                              </label>
                              <label>
                                <Field
                                  type='email'
                                  name='email'
                                  onBlur={e => {
                                    handleCheckEmail(e)
                                  }}
                                  placeholder='Contact Email'
                                  className='inputFieldBooking'
                                />
                                <ErrorMessage
                                  className='errorMessageModalValidation'
                                  name='email'
                                  component='div'
                                />
                              </label>
                              <div className='bookingButtonBody'>
                                <button
                                  type='submit'
                                  // onClick={handleClose}
                                  className={
                                    isMobile ? 'modalButton' : 'modalButton2'
                                  }
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </Form>
                        }
                      </Formik>
                    </div>
                  </div>
                  <div className='whyChooseUs'>
                    <h6> Fix Departure Dates</h6>
                    <p style={{ marginTop: '1em' }}> May - 3, 10, 17, 24</p>
                    <hr />
                    <p> June - 01, 07, 14, 21, 28</p>
                    <hr />
                    <p>July - 05, 12, 20, 26</p>
                    <hr />
                    <p>August - 2, 10, 16, 23, 30 </p>
                    <hr />

                    <p>September - 6, 13, 20, 25 </p>
                    <hr />

                    <p>October - 4 </p>
                  </div>
                  <div className='whyChooseUs'>
                    <h6> Why Book With Us?</h6>
                    <p style={{ marginTop: '1em' }}>
                      {' '}
                      Local agent no middle man
                    </p>
                    <hr />
                    <p> Customer care available 24/7</p>
                    <hr />
                    <p>Hand-picked Tours & Activities </p>
                    <hr />
                    <p>Free Complimentary Riding Gears </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <Footer />
        </>
      )}
    </>
  )
}

export default PackageMainPage
