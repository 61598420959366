import React from "react";
import { useNavigate } from "react-router-dom";
import SmallServiceButton from "../../Atoms/Buttons/ServiceCard/SmallServiceButton";
import "./smallCard.css";
import LandingBGNew2 from "../../../assets/CAMPS.jpeg";


function HealthRisk() {
  const navigate = useNavigate();

  const handleRedirect=()=> {
  
      navigate("/coming-soon");

    
  }

  return (
    <div className="small-card1"
    style={{
      backgroundImage: `url(${LandingBGNew2})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
    }}
    >
      <div className="small-card1-body">
        <div className="small-card1-title">
          CAMPS IN <br />
          PANGONG
        </div>
        {/* <div className="small-card1-para">
          <span >
          </span>
          Get a comprehensive understanding of your health with a click of a button
        </div> */}
      </div>
      <div
        className="small-card-img"
      >
        <a onClick={handleRedirect} onTouchStart={handleRedirect}>
          <SmallServiceButton text={"Check Now"} />
        </a>
      </div>
    </div>
  );
}

export default HealthRisk;
