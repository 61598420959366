import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchModalInput.css";

function SearchModalInput({ data }) {
  const navigate = useNavigate();

  const handleRedirection = async (item) => {
    window.location.href = "/Search?item=true&name=" + item.name+"&uuid="+item.attributeUuid;

  }
   
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    }
  }, [window.outerWidth]);



  return (
    <div>
      {isMobile ?
        (
          <div className="SearchInputModalMainContainerMobile" >
            <div className="SearchInputModalMainContainerGrid">
              <div>
                {data?.length > 0 ? (
                  data?.map((item) => (
                    <div
                      className="SearchModalMainSubTextMobile"
                      onClick={() => handleRedirection(item)}
                    >
                      {item.name}
                    </div>
                  ))
                ) : (
                  <div className="SearchModalInputMainSubText">No Records Found</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="SearchInputModalMainContainer">
            <div className="SearchInputModalMainContainerGrid">
              <div>
                {data?.length > 0 ? (
                  data?.map((item) => (
                    <div
                      className="SearchModalInputMainSubText"
                      onClick={() => handleRedirection(item)}
                    >
                      {item.name}
                    </div>
                  ))
                ) : (
                  <div className="SearchModalInputMainSubText">No Records Found</div>
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default SearchModalInput;
