import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-simply-carousel";
import landingNew1 from "../../../assets/bungee.png";
import landingNew2 from "../../../assets/bike24.jpeg";
import landingNew3 from "../../../assets/rafting.jpeg";

import landingNew4 from "../../../assets/h8.jpeg";
import landingNew5 from "../../../assets/CAR1.jpeg";
import HealthCheckup from "../../Molecules/LargeCards/HealthCheckup";
import Rental from "../../Molecules/LargeCards/Rental";
import Cashless from "../../Molecules/SmallCards/Cashless";
import Diagnostics from "../../Molecules/SmallCards/Diagnostics";
import HealthRisk from "../../Molecules/SmallCards/HealthRisk";
import OurPlans from "../../Molecules/SmallCards/OurPlans";
import "./ServiceCards.css";

function ServiceCards(props) {
  const [activeSlide, setActiveSlide] = useState(0);
  const [autoPlayChanges, setAutoPlayChanges] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  // function AutoPlay() {
  //   setAutoPlayChanges(false);
  // }

  useEffect(() => {
    setAutoPlayChanges(true);
  }, [props.autoPlayChanges1]);

  useEffect(() => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);
  
  const navigate = useNavigate();

  let image = [
    {
      Name: "BIKE & SUV  ",
      Name1: "PACKAGES 2024",

      Image: landingNew2,
      Button: "View Now",
      Price: " Empower Yourself with Knowledge: ",
      Price2: " Get a comprehensive understanding about your health.",
      href:
        localStorage.getItem("token") !== null
          ? "/hraLocked"
          : "/HealthRiskAssessment",
    },
    {
      Name: "RAFTING ",
      Name1: " 2024",
      Image: landingNew3,
      Button: "Check Now",
      Price2:
        "Explore our extensive list of subscription plans and pick the one that is most suited to you.",
      Price: " ",
      href: "/packages",
    },
    {
      Name: "HOTELS",
      Image: landingNew4,
      Button: "View Now",
      Price: "Make Preventive Health Checkups a Priority",

      Price2: " Be on top of your health and avail various health checkups at a fraction of their cost..",
      // href: "/HealthRiskAssessment",
      href: "/HealthCheckups",
    },
   
  
    {
      Name: "RENT BIKE",
      Name1: "",

      Image: landingNew2,
      Button: "View Now",
      Price: "Comprehensive Diagnostic Services  ",
      Price2: "Diagnostic Tests have never been easier",
      href:
        localStorage.getItem("token") !== null
          ? "/hraLocked"
          : "/HealthRiskAssessment",
    },
   
    {
      Name: "ACTIVITIES ",
      Name1: "BOOKING",
      Image: landingNew1,
      Button: "View Now",
      Price2:
        "Our consultation services are designed to work in collaboration with your overall healthcare plan",
      Price: "Collaborative Care ",

      href: "/CashlessOPD",
    },
   
   
   

    {
      Name: "BOOK TAXI",
      Image: landingNew5,
      Button: "View Now",

      Price2:
        "Choose to rent equipment for as long or as short a time as you need, Ensuring that you always have access to the equipment you need to maintain your independence and mobility",
      Price:
        " ",
      // href: "/HealthCheckups",
      href: "/Rental",
    },
  ];

  function handleClick(e) {
    navigate(`${e}`);
  }

  return (
    <div>
      {isMobile ? (
        <>
        
          <Carousel
            style={{ maxWidth: "450px !important" }}
            className="carouselMain1"
            containerProps={{
              style: {
                // transition: "all 1s",
                display: "flex",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                userSelect: "text",
                maxHeight: "75vw",
                marginTop: "-7vw",
                zIndex: 999,
                maxWidth: "100%",
              },
            }}
            swipeTreshold={10}
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            easing="linear"
            backwardBtnProps={{
              children: "",
              style: {
                width: 10,
                height: 10,
                marginBottom: "2em",
                position: "absolute",
                left: "4vw",
                top: "11em",
                zIndex: 999,
                borderRadius: "100%",
                border: "hidden",
              },
            }}
            forwardBtnProps={{
              children: "",
              style: {
                width: 10,
                height: 10,
                marginBottom: "2em",
                position: "absolute",
                top: "11em",
                right: "4vw",
                zIndex: 999,
                borderRadius: "100%",
                border: "hidden",
              },
            }}
            // autoplay={true}
            itemsToShow={1}
            speed={400}
          >
            {image.map((item, index) => (
              <div
                // onTouchStart={() => {
                //   AutoPlay();
                // }}
                style={{
                  width: "100%",
                  maxHeight: "24.75em",
                  minHeight: "24em",
                  paddingTop: 10,
                  marginBottom: "5vh",
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${item.Image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "101%",
                    marginLeft: "-3.2vw",
                    marginRight: "2vw",
                    zIndex: 99,
                  }}
                >
                  <div
                    className="hloa"
                    style={{
                      height: "70vw",
                      width: "106vw",

                      marginTop: "3vw",
                      background: "rgba(45, 4, 0, 0.47)",
                      boxShadow: " 0px 0px 2px 2px rgba(0, 0, 0, 0.1)",

                      // background: "rgba(49, 56, 51, 0.6)",
                      // boxShadow: "0px 0px 4px 4px rgba(0,0,0,0.1)",

                      padding: "100px",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        width:
                          item.Name === "Health Risk Assessment" ? "5.4em" : "",
                      }}
                      className="carouselsNameMbl"
                    >
                      {item.Name}
                      <br />
                      {item.Name1}
                    </div>
                    <div className="carouselPriceMbl">
                      <div className="LandingtextMobile"> {item.Price}</div>

                      {item.Price2}
                    </div>

                    <button
                      className="landingCarouselBtn"
                      onClick={() => handleClick(item.href)}
                    >
                      {item.Button}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </>
      ) : (
        <div className="firstPage">
          <div className="containers">
            <div className="row1">
              <HealthCheckup />
              <HealthRisk />

              <OurPlans />
            </div>
            <div className="row2">
              <Diagnostics />
              <Cashless />
              <Rental />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ServiceCards;
