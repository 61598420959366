import React from "react";
import Dental from "../../../assets/LandingPage/landingPics4.png";
import Dental2 from "../../../assets/LandingPage/landingPics5.png";

import Dental3 from "../../../assets/LandingPage/landingPics6.png";
import LandingBGNew1 from "../../../assets/CAR1.jpeg";

import LargeServiceButton from "../../Atoms/Buttons/ServiceCard/LargeServiceButton";
import "./Rental.css";

function Rental() {
  return (
    <div className="large-card"
    
    style={{
      backgroundImage: `url(${LandingBGNew1})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
    }}>
      <div className="large-card-body">
        <div className="large-card-text">
          <div className="largeCardTitle">Trekking 2024</div>
          {/* <div className="largeCardPara">
          
           
            Easy and safe access to quality healthcare equipment for as long as you need 
          </div> */}
          <a href="/coming-soon">
            <LargeServiceButton text={"View Now"} />
          </a>
        </div>
      </div>
      {/* <div
        className="large-card-img"
        style={{ backgroundImage: `url(${Dental2})` }}
        ></div>
      <div
        style={{ backgroundImage: `url(${Dental})` }}
        className="large-card-img1"
      ></div>
      <div
        className="large-card-img2"
        style={{ backgroundImage: `url(${Dental3})` }}
      ></div> */}
    </div>
  );
}

export default Rental;
