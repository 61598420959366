import MenuIcon from "@mui/icons-material/Menu";
import { Avatar } from "@mui/material";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import shoppingcart from "../../../assets/LandingPage/Shopping cart.png";
import myAccountIcon1 from "../../../assets/MyAccount/myAccountIcon1.png";
import myAccountIcon3 from "../../../assets/MyAccount/myAccountIcon3.png";
import myAccountIcon5 from "../../../assets/MyAccount/myPrescription.png";
import myAccountIcon6 from "../../../assets/MyAccount/purchaseHistory.png";
import myAccountIcon7 from "../../../assets/MyAccount/myCorporateProgram.png";
import myAccountIcon8 from "../../../assets/MyAccount/mySubscription.png";
import nm7 from "../../../assets/MyAccount/nm7.png";
import logo from "../../../assets/journeythroughladakh.png";
import mLOGO from "../../../assets/journeythroughladakh.png";
import magnifying from "../../../assets/common/magnifying.png";


// import SearchModal from "../../Organisms/Navbar/SearchModal";
import Hamburger from "./Hamburger";
import "./Navbar.css";
import SearchModalInput from "./SearchModalInput";
import backCover from "../../../assets/common/backCover.png";
import NavigationTabs from "../NavigationTabs/NavigationTabs";

export default function Navbar2() {
  const [isMobile, setIsMobile] = useState(false);
  const [cartIsEmpty, setCartIsEmpty] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openmodal, setopenModal] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  // const dispatch = useDispatch();

  // const getPopularPackage = useSelector(
  //   (state) => state.landing.getPopularPackage
  // );
  // const getPopularPlan = useSelector((state) => state.landing.getPopularPlan);
  // const getPopularTest = useSelector((state) => state.landing.getPopularTest);
  // const elasticSearch = useSelector((state) => state.elasticSearch);

  useEffect(() => {
    // dispatch(getPopularPackageRequest(8));
    // dispatch(getPopularPlanRequest());
    // dispatch(getPopularTestRequest(8));
  }, []);

  const [badgeCount, setBadgeCount] = useState();
  const modalRef = useRef();

  const handleModal = () => {
    setopenModal(true);
  };

  const handleInputModal = (event) => {
    // if (event.target.value.length >= 3) {
    //   setopenModal(false);
    //   setOpenSearchModal(true);
    //   dispatch(elasticSearchRequest(event.target.value));
    // } else {
    //   setopenModal(true);
    //   setOpenSearchModal(false);
    // }
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setopenModal(false);
        setOpenSearchModal(false);
        document.getElementById("search").value = "";
      }
    };
    const handleNavigationPopState = () => {
      setopenModal(false);
      setOpenSearchModal(false);
    };
    document.addEventListener("click", handleClick, handleInputModal);
    document.addEventListener("popstate", handleNavigationPopState);
    return () => {
      document.removeEventListener(
        "click",
        handleClick,
        handleNavigationPopState
      );
    };
  }, [openmodal]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value.length >= 3) {
      window.location.href = "/Search?item=false&result=" + event.target.value;
    }
  };

  const handleCartClick = (event) => {
    const itemCount = badgeCount > 0 ? badgeCount : 0;
    if (itemCount === 0) {
      setCartIsEmpty(true);
      setAnchorEl(event.currentTarget);
    } else {
      window.location.href = "/cart";
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // const handleBadge = () => {
    //   setTimeout(
    //     () =>
    //       setBadgeCount(
    //         localStorage.getItem("itemDtoList") !== null
    //           ? JSON.parse(localStorage.getItem("itemDtoList")).length
    //           : undefined
    //       ),
    //     1000
    //   );
    // };
    // handleBadge();
    // return () => {
    // };
  }, []);

  // const createOrder = useSelector((state) => state.cart.createOrder);



  // useEffect(() => {
  //     dispatch(getCartGuestRequest());
  //   } else {
  //     dispatch(getCartLoginRequest());
  //   }
  // }, []);

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    }
  }, [window.outerWidth]);

  const [ans, setAns] = useState(false);

  const handleAns = () => {
    setAns(!ans);
  };

  const cityNames = [];

  const [islogin, setlogin] = useState(false);

  useEffect(() => {
    // dispatch(viewAllCityRequest());
  }, []);

  // const viewCityNames = useSelector((state) => state.landing.viewAllCity);

  // viewCityNames?.data?.data?.data.data.map((item) => {
  //   cityNames.push(item.name);
  // });

  const handleLogout = () => {
   
  };

  return (
    <div>
      {isMobile ? (
        <>
          <div
            style={{
              backgroundImage: `url(${backCover})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "auto",
            }}
          >
            <div style={{ paddingBottom: "4vw" }}>
              <div className="mobileMainBox">
                <div className="MnavbarTitle">
                  <div className="i">
                    <a className="navLogo" href="/">
                      <img src={mLOGO} alt="error" className="navbarLogo" />
                    </a>
                  </div>
                </div>
                <div className="mblCarts">
                  <a className="cartMbl">
                    {/* <button
                      className="shoppingBagButton"
                      onClick={handleCartClick}
                    >
                      <Badge
                        badgeContent={badgeCount === undefined ? 0 : badgeCount}
                        color="primary"
                      >
                        <img src={shoppingcart}></img>
                      </Badge>
                    </button> */}
                    {cartIsEmpty && (
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Typography sx={{ p: 2 }} color=" #1D2334">
                          Your Cart Is Empty!
                        </Typography>
                      </Popover>
                    )}
                  </a>
                </div>
                <div className="mblMenu">
                  {" "}
                  <MenuIcon onClick={handleAns} />{" "}
                </div>
                {ans && <Hamburger open={setAns} />}{" "}
              </div>
              {/* <div className="searchBar" ref={modalRef}>
                <input
                  class="navSearchBar"
                  type="search"
                  placeholder="Search for Tests and Packages"
                  aria-label="Search"
                  onClick={handleModal}
                  onKeyPress={handleKeyPress}
                  onChange={handleInputModal}
                  id="search"
                  autoComplete="off"
                />
                <a>
                  {" "}
                  <img src={magnifying} />
                </a>
                {openmodal && (
                  <SearchModal
                    open={setopenModal}
                    getPopularPackage={getPopularPackage}
                    getPopularPlan={getPopularPlan}
                    getPopularTest={getPopularTest}
                  />
                )}
                {openSearchModal && (
                  <SearchModalInput data={elasticSearch.data?.data.data.data} />
                )}
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            backgroundImage: `url(${backCover})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <div className="navbarMainBox">
            <div>
              <div className="mainBoxChild">
                <div className="navbarTitle">
                  <a href="/">
                    <img src={logo} alt="error" className="navbarLogo" />
                  </a>
                </div>

                <div className="searchBar" >
                
                Ladakh's Premier Women-Led Travel Agency! 
                
                  
                </div>

                <div className="statusdiv">
                 Since 1994
                </div>
              </div>
              <hr className="navbarBorderLine"></hr>
              <div>
                <NavigationTabs />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
