import React, { useState, useEffect } from 'react'

import './ImageHover.css'

import { Link } from 'react-router-dom'
import ReserveForm from '../../../Pages/ReserveForm/ReserveForm'
import landingNew3 from '../../../../assets/rafting.jpeg'
import landingNew2 from '../../../../assets/zipline.jpeg'
import landingNew1 from '../../../../assets/bungee.png'

const Snip1193 = ({ imageSrc, altText, title }) => {
  const handleMouseLeave = event => {
    event.target.classList.remove('hover')
  }
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window.outerWidth])
  const [open, setOpen] = React.useState(false)
  const [add, setAdd] = React.useState(false)
  const handleAdd = event => {
    setAdd(true)
  }
  return (
    <>
      {add && <ReserveForm open={setAdd} />}

      {isMobile ? (
        <div className='roomImeageBoxMobile'>
          <figure className='snip1193' onMouseLeave={handleMouseLeave}>
            {/* <img src={imageSrc} alt={altText} /> */}
            <div
              style={{
                backgroundImage: `url(${imageSrc})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '60vw'
              }}
              className='landingPag3eImg2'
            ></div>
            <figcaption>
              <h4>{title}</h4>
              <p style={{ marginBottom: '10vw' }} className='hoverContentRoom'>
                {altText}
              </p>
              {/* <a href="#" className="bottom-left"><i className="ion-ios-chatboxes-outline"></i></a> */}
              <a href={`https://wa.me/916005537169?text=Hello, I want to enquire about  ${title} ?`} target="_blank" className='bottom-right'>
                <i className='ion-ios-telephone-ouwtline'>Enquire Now</i>
              </a>
              {/* <a href="#" className="bottom-right"><i className="ion-ios-telephone-outline">Hello</i></a> */}
            </figcaption>
          </figure>
        </div>
      ) : (
        <div className='roomImeageBox'>
          <figure className='snip1193' onMouseLeave={handleMouseLeave}>
            {/* <img src={imageSrc} alt={altText} /> */}
            <div
              style={{
                backgroundImage: `url(${imageSrc})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '30vw'
              }}
              className='landingPag3eImg2'
            ></div>
            <figcaption>
              <h4>{title}</h4>
              <p className='hoverContentRoom'>{altText}</p>
              {/* <a href="#" className="bottom-left"><i className="ion-ios-chatboxes-outline"></i></a>
        <a href="#" className="bottom-right"><i className="ion-ios-telephone-outline"></i></a> */}
              {/* <a href="#" className="bottom-right"><i className="ion-ios-telephone-outline">Hello</i></a> */}
            </figcaption>
          </figure>
        </div>
      )}
    </>
  )
}

const ImageHover = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [window.outerWidth])

  return (
    <div className='roomBody'>
      <div className='roomBody2' style={{ paddingLeft: '1vw' }}>
        <h2 className='roomHeading2'>ACTIVITIES</h2>
        <p className='roomHeading1'>
          Adventure Beckons: Explore Ladakh's Majestic Landscapes with Thrilling
          Activities!
        </p>
      </div>
      <>
        {isMobile ? (
          <div className='roomImageBoxMobile'>
            <Snip1193
              imageSrc={landingNew3}
              altText="Thrill on Zanskar's Rapids: Ladakh's Ultimate Rafting Experience!"
              title='RAFTING'
            />
            <Snip1193
              imageSrc={landingNew2}
              altText="Fly Over Ladakh's Majestic Terrain: Zip Line Adventure Awaits!"
              title='ZIP LINING'
            />
            <Snip1193
              imageSrc={landingNew1}
              altText="Leap into the Thrill: Bungee Jumping Over Zanskar River in Ladakh!"
              title='BUNGEE JUMPING'
            />
          </div>
        ) : (
          <>
            <div className='roomImageBox'>
              <Snip1193
                imageSrc={landingNew3}
                altText="Thrill on Zanskar's Rapids: Ladakh's Ultimate Rafting Experience!"
                title='RAFTING'
              />
              <Snip1193
                imageSrc={landingNew2}
                altText="Fly Over Ladakh's Majestic Terrain: Zip Line Adventure Awaits!"
                title='ZIP LINING'
              />
              <Snip1193
                imageSrc={landingNew1}
                altText="Leap into the Thrill: Bungee Jumping Over Zanskar River in Ladakh!"
                title='BUNGEE JUMPING'
              />
            </div>
          </>
        )}
      </>

      <div className={isMobile ? 'viewMoreRoom' : 'viewMoreRoom1'}>
        {/* <Link to='Room'>View More</Link> */}
      </div>
    </div>
  )
}

export default ImageHover
