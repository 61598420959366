import React, { useEffect, useState } from "react";
import AboutUsHeading from "../../../assets/4.jpeg";
import coming from "../../../assets/cooming.png";

import { Helmet } from "react-helmet";


import Footer from "../Footer/Footer";

import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";


function Soon() {

  return (
    <>
    <ScrollToTop/>
    <Helmet>
        <title>About Ladakh Travel | Luxury Boutique Retreat in Ladakh</title>
        <meta
          name="description"
          content="Discover the allure of Ladakh Heights, a luxury boutique retreat located just 4kms from the bustling market. Enjoy captivating panoramas, spacious rooms, and traditional aesthetics amidst breathtaking vistas. Book your stay now!"
        />
        <meta
          name="keywords"
          content="ladakh heights, ladakh hotel, ladakh boutique hotel, ladakh luxury hotel, ladakh retreat, ladakh accommodations"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* {isMobile ? (
        <> */}
        <div className="AboutUsBody">
        
    <img style={{width:"100vw"}}src={coming} alt="error"></img>
    <div style={{display:"flex",justifyContent:"center",alignItems:"center", width:"100%", marginBottom:"4vw"}}>

    <button  ><a href="/">Home</a></button>
    </div>
          
        </div>
        <Footer />
        {/* <ReserveForm /> */}
        {/* <ViewGallery  /> */}
        {/* <Testimonal /> */}
        {/* <Map /> */}
      {/* </>
      ) : (
        <> */}
          <div className="AboutUsBody">
           

           
          </div>
          <Footer />
          {/* <ReserveForm /> */}
          {/* <ViewGallery  /> */}
          {/* <Map /> */}
        {/* </> */}
      {/* )} */}
    </>
  );
}

export default Soon;
